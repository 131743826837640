import React, {useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import styled from "styled-components";
import {Input, message, Select} from "antd";
import {useToken} from "../../../../app/hooks";
import {useNavigate} from "react-router";
import {KoCurrency} from "../../../../utils/KoCurrency";

import API from "../../../../utils/api";
import ReactInicis from "../../../../utils/ReactInicis/ReactInicisCustom";


export const DepositRecharge = ({setRechargeModal, mutateAdvertiserTransactionList}) => {
    const [payType, setPayType] = React.useState(2);
    const [amount, setAmount] = React.useState(0);

    // 데이터 조회
    const {data: token} = useToken();

    const navigate = useNavigate();

    const BadgeItem = [
        {name: '1만', value: 10000},
        {name: '3만', value: 30000},
        {name: '5만', value: 50000},
        {name: '10만', value: 100000},
        {name: '100만', value: 1000000},
    ]

    const PayInfo = [
        {name: '카드결제', value: 2},
    ]


    const [isPurchase, setIsPurchase] = useState(0);

    const [payData, setPayData] = useState({
        mid: "myhometow1",
        productName: "prdName",
        buyerName: "hellojh",
        buyerTel: "01011112222",
        buyerEmail: "test@test.com",
        productPrice: 1000,
        payStatus: 0,
        returnUrl: "https://myhometown-ad.co.kr/admin/deposit",
        closeUrl: "https://myhometown-ad.co.kr/payment-close",
        mKey: ''
    })


    // mKey를 서버에서 가져와 payData에 설정하는 함수
    const fetchMKey = async (amount, name) => {
        try {
            const res = await API.post('/advertiser/payment/initiate', token + '', { amount });
            if (res.result.success) {
                let mKey = res.result.result.mkey;
                let returnUrl = res.result.result.returnUrl;
                let buyerName = res.result.result.buyername;
                let buyerTel = res.result.result.buyertel;
                let buyerEmail = res.result.result.buyeremail;
                let mid = res.result.result.mid;
                let verification = res.result.result.verification;
                let oid = res.result.result.oid;
                let signature = res.result.result.signature;
                let timestamp = res.result.result.timestamp;
				let acceptmethod = res.result.result.acceptmethod;

                

                // console.log('mKey', mKey);
                setPayData(prevData => ({ ...prevData, mKey, productName:name, productPrice: amount, returnUrl, buyerName, buyerTel, buyerEmail, mid, verification, oid, signature, timestamp , acceptmethod })); // mKey 설정

                setIsPurchase(isPurchase + 1); // 결제 트리거 상태 업데이트

            } else {
                message.error(res.result.message);
            }
        } catch (err:any) {
            message.error(err);
        }
    };

    function numberToKorean(num) {
        const units = ["", "만", "억", "조", "경"];
        const koreanNumbers = ["", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
        const smallUnits = ["", "십", "백", "천"];

        if (num === 0) return "영원";

        let result = "";
        let unitIndex = 0;

        while (num > 0) {
            let part = num % 10000;
            num = Math.floor(num / 10000);

            if (part > 0) {
                let partResult = "";
                let smallUnitIndex = 0;

                while (part > 0) {
                    const digit = part % 10;
                    if (digit > 0) {
                        partResult = koreanNumbers[digit] + smallUnits[smallUnitIndex] + partResult;
                    }
                    part = Math.floor(part / 10);
                    smallUnitIndex++;
                }

                result = partResult + units[unitIndex] + result;
            }

            unitIndex++;
        }

        return result;
    }



    const handleChange = (e) => {
        const input = e.target.value.replace(/[^0-9]/g, ""); // 숫자만 허용
        setAmount(input);
    };

    useEffect(() => {
        console.log('amount', amount)
    }, [amount]);



    return (
      <>
          <Blacked onClick={() => setRechargeModal(null)}/>
          <ModalWrapper className={"scroll-container"}>
              <ModalHeader>
                  <ModalTitle>울동네 광고-광고주 예치금 충전</ModalTitle>
                  <CloseIconSVG as={ModalCloseIcon} onClick={() => {setRechargeModal(null)}  }/>
              </ModalHeader>

              <ReactInicis payData={payData} isPurchase={isPurchase} isTest={false}/>

              {/*<button onClick={() => fetchMKey(1000)}>dd</button>*/}

              <Table>
                  <tbody>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              충전금액
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          <div>
                              <InputWrap>
                                  <InputText value={KoCurrency(amount)}
                                             onChange={handleChange} suffix={'원'}
                                             placeholder={'직접입력'}/>

                                  {amount ? <div>{`(${numberToKorean(amount) + ' 원'})`}</div> : <></>}
                              </InputWrap>

                              <BadgesWrapper>
                                  {BadgeItem.map((item, index) => (
                                    <Badge onClick={() => setAmount(item.value)}>{item.name}</Badge>
                                  ))}
                              </BadgesWrapper>
                          </div>
                      </Td>
                  </TableRow>

                  <TableRow>
                      <Th>
                          <ThLabel>
                              결제방법
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          <StyledSelect value={payType} onChange={(e: any) => setPayType(e)}>
                              {PayInfo.map((item, index) => (
                                  <Select.Option value={item?.value}>
                                      {item?.name}
                                  </Select.Option>
                              ))}
                          </StyledSelect>
                      </Td>
                  </TableRow>
                  </tbody>
              </Table>

              <DescContents>
                  {`- 포인트 충전금액 결제 후 잔여금이 1년 초과시 당사 서비스 제공은 불가합니다.
                  
- 사업자의 상품대금 환급 의무
   인터넷쇼핑몰 사업자는 다음의 어느 하나에 해당하는 날부터 3영업일 이내에 이미 지급 받은 물품 등의 
   대금을 환급해야 합니다. 만약 물건의 대금 환급을 지연하면 그 지연기간에 대해 연 15％의 지연이자를 
   별도로 지급해야 합니다(규제「전자상거래 등에서의 소비자보호에 관한 법률」 
   제18조제2항 및 규제「전자상거래 등에서의 소비자보호에 관한 법률 시행령」 제21조의3).
   
- 신용카드·전자상품권 등으로 결제한 경우
   소비자가 신용카드나 그 밖의 결제수단으로 물품 등의 대금을 지급한 경우 인터넷쇼핑몰 사업자는 그 대금을 
   환급할 때 지체 없이 결제업자에게 그 물품 등의 대금 청구를 정지 또는 해당 결제가 이루어진 결제수단으로만 
   취소하도록 요청해야 합니다(규제「전자상거래 등에서의 소비자보호에 관한 법률」 제18조제3항 본문).
   인터넷쇼핑몰 사업자가 신용카드사 등의 결제업자로부터 물품 등의 대금을 이미 지급받은 경우에는 지체 없이 
   이를 결제업자에게 환급하고, 그 사실을 소비자에게 알려야 합니다
   (규제「전자상거래 등에서의 소비자보호에 관한 법률」 제18조제3항 단서).
   이 경우 사업자가 정당한 사유 없이 결제업자에게 대금을 환급하지 않으면 소비자는 환급받을 금액에 대해 
   결제업자에게 해당 인터넷쇼핑몰 사업자에 대한 다른 채무와 상계할 것을 요청할 수 있습니다
   (규제「전자상거래 등에서의 소비자보호에 관한 법률」 제18조제6항).
   만약, 결제업자가 위 상계요청을 정당한 사유 없이 게을리 한다면 소비자는 그 결제업자에 대해 대금의 
   결제를 거부할 수 있습니다. 이 경우 인터넷쇼핑몰 사업자와 결제업자는 그 결제의 거부를 이유로 해당 
   소비자를 약정한 기일 이내에 채무를 변제하지 않은 사람(연체자나 신용불량자 등)으로 처리하는 등 
   소비자에게 불이익을 주는 행위를 해서는 안 됩니다
   (규제「전자상거래 등에서의 소비자보호에 관한 법률」 제18조제7항).

- 인터넷쇼핑몰의 휴업기간 및 영업정지기간 중의 반품
   인터넷쇼핑몰 사업자는 휴업기간이나 영업정지기간에도 주문취소 및 반품 등의 업무와 대금 환급과 관련된 
   업무를 계속해야 합니다
   (규제「전자상거래 등에서의 소비자보호에 관한 법률」 제22조제1항).
   
   공정거래위원회는 사업자가 위를 위반하는 행위를 하는 경우에는 해당 사업자에게 그 시정조치를 
   명할 수 있습니다
   (규제「전자상거래 등에서의 소비자보호에 관한 법률」 제32조제1항제1호).
   
- [나이츠랩]은 모든 거래에 대한 책임과 취소,환불,민원등의 처리는 [나이츠랩]에서 진행합니다.
   민원 담당자 신임식 / 연락처 010-2354-2459
  `}
              </DescContents>

              <ButtonsWrapper>
                  <RegistButton onClick={() => {
                      // onChangePrice(amount, `울동네 광고-광고주 예치금 - ${KoCurrency(amount)}원`);
                      fetchMKey(amount, `울동네 광고-광고주 예치금 - ${KoCurrency(amount)}원`);
                  }}>
                      결제하기
                  </RegistButton>
              </ButtonsWrapper>
          </ModalWrapper>
      </>
    )
}

const StyledSelect = styled(Select)`
	width: 163px;
	height: 36px;
	border-radius: 4px;
`

const DescContents = styled.div`
    white-space: pre-wrap;
    margin-top: 20px;
    font-size: 12px;
    color: #8b8d8e;
`

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 808px;
    width: 580px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Table = styled.table`
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 20%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
    vertical-align: middle;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    
`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
`;

const InputText = styled(Input)`
    width: 160px;
    height: 28px;
    padding-left: 10px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;

    &::placeholder {
        color: #535353;
    }
`;

const InputWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`

const ButtonsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const RegistButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    background-color: #530DAD;
    color: white;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`

const BadgesWrapper = styled.div`
    display: flex;
    gap: 4px;
    margin-top: 10px;
`

const Badge = styled.div`
    background-color: #F5F5F5;
    padding: 2px 4px;
    cursor: pointer;
    font-family: Pretendard, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    color: #000000B2;
`
