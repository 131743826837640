import { useWindowDimensions } from "app/hooks";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import Slider from "react-slick";
import styled, { keyframes } from "styled-components";
import { useAgentMediaList } from "../../hooks/DigitalSignage/GetAgentMediaList";
import { FullImageList } from "../FullImageList";
import { Six } from "./Six";
import { Two } from "./Two";
import {useUserInfo} from "../../../HomeTown/hooks/Auth/GetUserInfo";

const DigitalSignageSlickSetting = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: 'ondemand',
};

export const DigitalSignage = () => {
  // 유틸리티
  const [saleItem, setSaleItem] = useState<any>([]);
  const [localItem, setLocalItem] = useState<any>([]);
  const [intervalTime, setIntervalTime] = useState(12000); // 기본 시간

  const { width, height } = useWindowDimensions();

  const [zoom, setZoom] = useState(1);
  const [lMargin, setLMargin] = useState(0);
  const [tMargin, setTMargin] = useState(0);

  const [isLastSlide, setIsLastSlide] = useState(false);

  const captureRef = useRef<HTMLDivElement | null>(null);

  // 유저 정보
  const {userInfo} = useUserInfo();

  useEffect(() => {
    //크기
    const w = captureRef?.current?.clientWidth || 1;
    const h = captureRef?.current?.clientHeight || 1;

    const leftmargin = (width - w * zoom) / 2;
    setLMargin(leftmargin);

    const topmargin = (height - h * zoom) / 2;

    setTMargin(topmargin);

    //console.log("width", width, w * zoom);
  }, [width, height, zoom]);

  useEffect(() => {
    let z = 1;
    let zh = 1;

    if (width < 1920) {
      z = width / 1920;
    }

    if (height < 1080) {
      zh = height / 1080;
    }

   // console.log("z", z, width, height);
    setZoom(Math.min(z, zh));
  }, [width, height]);

  // 데이터 조회
  const { agentMediaList, mutateAgentMediaList, total:mediaTotal } = useAgentMediaList();

  const allSeatInfo = useRef<any>([]);

  const [all, setAll] = useState<any>([]);



  // 매물, 외부 데이터로 나누기
  useEffect(() => {
    if (agentMediaList && agentMediaList?.length > 0) {
      // console.log("agentMediaList", agentMediaList);

      setSaleItem(agentMediaList[0]);
      setLocalItem(agentMediaList[1]);
    }
  }, [agentMediaList]);

  const [mergedItems, setMergedItems] = useState<any>([]);

  useEffect(() => {
    const tempm = [];
    const maxLength = Math.max(saleItem?.length || 0, localItem?.length || 0) || 0;

    for (let i = 0; i < maxLength; i++) {
      if (saleItem?.[i]) {
        // @ts-ignore
        tempm.push({ type: "sale", set: saleItem[i] });
      }
      if (localItem?.[i]) {
        // @ts-ignore
        tempm.push({ type: "local", set: localItem[i] });
      }
    }

    setMergedItems(tempm || []);
  }, [saleItem, localItem]);

  useEffect(() => {
    const temp: any = [];

    for (let i = 0; i < mergedItems?.length; i++) {
      if (mergedItems[i]?.set) {
        for (let j = 0; j < mergedItems[i]?.set?.length; j++) {
          if (mergedItems[i]?.set[j]?.saleInfo) {
            temp.push(mergedItems[i]?.set[j]?.saleInfo);
          }
        }
      }
    }
    setAll(temp);
  }, [mergedItems]);



  useEffect(() => {
    console.log('mergedItems', mergedItems)
  }, [mergedItems]);

  const sliderRef = useRef<any>(null); // 슬라이더에 대한 참조 생성

  const goToNextSlide = () => {
    sliderRef.current.slickNext(); // 다음 슬라이드로 이동
  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev(); // 이전 슬라이드로 이동
  };

  const goToSpecificSlide = (index) => {
    sliderRef.current.slickGoTo(index); // 특정 슬라이드로 이동
  };


  useEffect(() => {

    console.log('intervalTime', intervalTime)

    const timer = setInterval(() => {
      goToNextSlide();
    }, intervalTime);

    return () => clearInterval(timer);
  }, [intervalTime]);



  const handleAfterChange = (currentSlide: number) => {
    // console.log('currentSlide', currentSlide, mediaTotal)

    // mediaTotal - 1
    if(mediaTotal){
      if (currentSlide === mediaTotal - 1) {
        setIsLastSlide(true);
      } else {
        setIsLastSlide(false);
      }
    }
  };

  useEffect(() => {

    if (isLastSlide) {
      setTimeout(() => {
        window.location.reload();
      }, 6000);

      //setIntervalTime(12000);
    }

  }, [isLastSlide]);


  return (
    <div>
      <Helmet>
        <style>
          {`
            body {
              margin: 0;
              padding: 0;
              overflow: hidden;
            }
          `}
          </style>
      </Helmet>
      <Page
        ref={captureRef}
        style={{
          transform: `scale(${zoom})`,
          transformOrigin: "left top",
          marginLeft: `${lMargin}px`,
          marginTop: `${tMargin}px`,
          width: "1920px",
          height: "1080px",
        }}
      >
        <Slider {...DigitalSignageSlickSetting} ref={sliderRef} lazyLoad="ondemand" afterChange={handleAfterChange} >
          {mergedItems &&
            mergedItems?.length > 0 &&
            mergedItems?.map((item, index) => {
              // @ts-ignore
              if (item?.type === "sale") {
                // return (
                //   <div>sale</div>
                // )

                // @ts-ignore
                return item?.set?.map((setItem, setIndex) => {

                  return setItem?.map((mainItem, mainIndex ) => {

                    // @ts-ignore
                    if (mainItem?.length === 1) {
                      return (
                        <ContentWrapper key={`sale-${index}-${setIndex}-${mainIndex}`}>
                          <FullContainer>
                            <FullImageList
                              imagelist={mainItem[0]?.imageUrlList.slice(0, 3)}
                              isWidth={true}
                              setItem={mainItem}
                              type={"full"}
                              num={0}
                              setIntervalTime={setIntervalTime}
                            />

                          </FullContainer>
                        </ContentWrapper>
                      );
                      // @ts-ignore
                    } else if (mainItem?.length === 2) {
                      return (
                        <ContentWrapper key={`sale-two-${index}-${setIndex}-${mainIndex}`}>
                          <TwoContainer>
                            <TwoLeftSide>
                              <TwoTopSide>
                                <FullImageList
                                  imagelist={mainItem[0]?.imageUrlList.length > 3 ? mainItem[0]?.imageUrlList.slice(3, 6) : mainItem[0]?.imageUrlList.slice(0, 3)}
                                  isWidth={true}
                                  setItem={mainItem}
                                  type={'two'}
                                  num={0}
                                  setIntervalTime={setIntervalTime}
                                />
                                {/*<Two item={setItem} num={0} type={"top"} />*/}
                              </TwoTopSide>

                              <TwoSaleBottomSide>
                                <Two
                                  item={setItem[2]}
                                  num={2}
                                  type={"bottom"}
                                  all={all}
                                />
                                <Two
                                  item={setItem[2]}
                                  num={3}
                                  type={"bottom"}
                                  all={all}
                                />
                              </TwoSaleBottomSide>
                            </TwoLeftSide>
                            <TwoRightSide>
                              <TwoTopSide>
                                <FullImageList
                                  imagelist={mainItem[1]?.imageUrlList.length > 3 ? mainItem[1]?.imageUrlList.slice(3, 6) : mainItem[1]?.imageUrlList.slice(0, 3)}
                                  isWidth={true}
                                  setItem={mainItem}
                                  type={'two'}
                                  num={1}
                                  setIntervalTime={setIntervalTime}
                                />

                                {/*<TwoTopSaleImgWrapper>*/}
                                {/*  /!*<BlurredSaleBackground backImg={setItem[1]?.imageUrlList && MEDIA_URL + setItem[1]?.imageUrlList[0]}/>*!/*/}

                                {/*  <FullImageList*/}
                                {/*    imagelist={setItem[1]?.imageUrlList.lenght > 3 ? setItem[1]?.imageUrlList.slice(3, 6) : setItem[1]?.imageUrlList.slice(0, 3)}*/}
                                {/*    isWidth={true}*/}
                                {/*  />*/}
                                {/*</TwoTopSaleImgWrapper>*/}

                                {/*<Two item={setItem} num={1} type={"top"} />*/}
                              </TwoTopSide>
                              <TwoSaleBottomSide>
                                <Two
                                  item={setItem[2]}
                                  num={4}
                                  type={"bottom"}
                                  all={all}
                                />
                                <Two
                                  item={setItem[2]}
                                  num={5}
                                  type={"bottom"}
                                  all={all}
                                />
                              </TwoSaleBottomSide>
                            </TwoRightSide>
                          </TwoContainer>
                        </ContentWrapper>
                      );
                      // @ts-ignore
                    } else if (mainItem?.length === 6) {
                      return (
                        <ContentWrapper key={`sale-six-${index}-${setIndex}-${mainIndex}`}>
                          <SixContainer>
                            <SixTopSide>
                              <Six item={mainItem} num={0} />
                              <Six item={mainItem} num={1} />
                              <Six item={mainItem} num={2} />
                            </SixTopSide>
                            <SixBottomSide>
                              <Six item={mainItem} num={3} />
                              <Six item={mainItem} num={4} />
                              <Six item={mainItem} num={5} />
                            </SixBottomSide>
                          </SixContainer>
                        </ContentWrapper>
                      );
                    }

                  })
                });
              }


              // @ts-ignore
              else if (item.type === "local") {
                // return (
                //   <div>local</div>
                // )

                // @ts-ignore
                return item?.set?.map((setItem, localIndex) => {

                  return setItem?.map((mainItem, mainIndex ) => {

                    if (mainItem && mainItem?.length === 1 && mainItem[0]?.imageUrlList) {
                      return (
                        <ContentWrapper key={`local-${index}-${localIndex}`}>
                          <FullLocalImageWrapper>
                            <FullImageList
                              imagelist={mainItem[0]?.imageUrlList}
                              isWidth={true}
                              type={"full"}
                              setItem={mainItem}
                              setIntervalTime={setIntervalTime}
                              num={0}
                            />
                            {/*<div>{JSON.stringify(mainItem[0]?.imageUrlList)}</div>*/}
                          </FullLocalImageWrapper>
                        </ContentWrapper>
                      );
                    } else if (mainItem?.length === 2 && mainItem[0]?.imageUrlList && mainItem[1]?.imageUrlList) {
                      return (
                        <ContentWrapper key={`local-two-${index}-${localIndex}`}>
                          <TwoContainer>
                            <TwoLeftSide>
                              <TwoTopImgWrapper>
                                {/*<BlurredBackground*/}
                                {/*  backImg={*/}
                                {/*    setItem[0]?.imageUrlList &&*/}
                                {/*    MEDIA_URL + setItem[0]?.imageUrlList[0]*/}
                                {/*  }*/}
                                {/*/>*/}

                                <FullImageList
                                  imagelist={mainItem[0]?.imageUrlList}
                                  isWidth={true}
                                  width={"923px"}
                                  height={"662px"}
                                  type={"six"}
                                  setItem={mainItem}
                                  num={0}
                                  setIntervalTime={setIntervalTime}
                                />
                                {/* <TwoTopImg
                              src={
                                setItem[0]?.imageUrlList &&
                                MEDIA_URL + setItem[0]?.imageUrlList[0]
                              }
                              width={'923px'}
                              height={'662px'}
                            /> */}
                              </TwoTopImgWrapper>
                              <TwoBottomSide>
                                <TwoBottomImgWrapper>
                                  {/*<BlurredBottomBackground*/}
                                  {/*  backImg={*/}
                                  {/*    setItem[0]?.imageUrlList &&*/}
                                  {/*    MEDIA_URL + setItem[0]?.imageUrlList[0]*/}
                                  {/*  }*/}
                                  {/*/>*/}

                                  <FullImageList
                                    imagelist={setItem[2][2]?.imageUrlList}
                                    isWidth={true}
                                    width={"452px"}
                                    height={"359px"}
                                    type={"six"}
                                    setItem={mainItem}
                                  />
                                  {/* <TwoBottomImg
                                src={
                                  setItem[0]?.imageUrlList &&
                                  MEDIA_URL + setItem[0]?.imageUrlList[0]
                                }
                                isWidth={true}
                              /> */}
                                </TwoBottomImgWrapper>
                                <TwoBottomImgWrapper>
                                  {/*<BlurredBottomBackground*/}
                                  {/*  backImg={*/}
                                  {/*    setItem[0]?.imageUrlList &&*/}
                                  {/*    MEDIA_URL + setItem[0]?.imageUrlList[0]*/}
                                  {/*  }*/}
                                  {/*/>*/}

                                  <FullImageList
                                    imagelist={setItem[2][3]?.imageUrlList}
                                    isWidth={true}
                                    width={"452px"}
                                    height={"359px"}
                                    type={"six"}
                                    setItem={mainItem}
                                  />
                                  {/* <TwoBottomImg
                                src={
                                  setItem[0]?.imageUrlList &&
                                  MEDIA_URL + setItem[0]?.imageUrlList[0]
                                }
                                isWidth={true}
                              /> */}
                                </TwoBottomImgWrapper>
                              </TwoBottomSide>
                            </TwoLeftSide>
                            <TwoRightSide>
                              <TwoTopImgWrapper>
                                {/*<BlurredBackground*/}
                                {/*  backImg={*/}
                                {/*    setItem[1]?.imageUrlList &&*/}
                                {/*    MEDIA_URL + setItem[1]?.imageUrlList[0]*/}
                                {/*  }*/}
                                {/*/>*/}
                                <FullImageList
                                  imagelist={mainItem[1]?.imageUrlList}
                                  isWidth={true}
                                  width={"923px"}
                                  height={"662px"}
                                  type={"six"}
                                  setItem={mainItem}
                                  num={1}
                                  setIntervalTime={setIntervalTime}
                                />
                                {/* <TwoTopImg
                              src={
                                setItem[1]?.imageUrlList &&
                                MEDIA_URL + setItem[1]?.imageUrlList[0]
                              }
                              isWidth={true}
                            /> */}
                              </TwoTopImgWrapper>
                              <TwoBottomSide>
                                <TwoBottomImgWrapper>
                                  {/*<BlurredBottomBackground*/}
                                  {/*  backImg={*/}
                                  {/*    setItem[1]?.imageUrlList &&*/}
                                  {/*    MEDIA_URL + setItem[1]?.imageUrlList[0]*/}
                                  {/*  }*/}
                                  {/*/>*/}
                                  <FullImageList
                                    imagelist={setItem[2][4]?.imageUrlList}
                                    isWidth={true}
                                    width={"452px"}
                                    height={"359px"}
                                    type={"six"}
                                    setItem={mainItem}
                                  />
                                  {/* <TwoBottomImg
                                src={
                                  setItem[1]?.imageUrlList &&
                                  MEDIA_URL + setItem[1]?.imageUrlList[0]
                                }
                                isWidth={true}
                              /> */}
                                </TwoBottomImgWrapper>
                                <TwoBottomImgWrapper>
                                  {/*<BlurredBottomBackground*/}
                                  {/*  backImg={*/}
                                  {/*    setItem[1]?.imageUrlList &&*/}
                                  {/*    MEDIA_URL + setItem[1]?.imageUrlList[0]*/}
                                  {/*  }*/}
                                  {/*/>*/}
                                  <FullImageList
                                    imagelist={setItem[2][5]?.imageUrlList}
                                    isWidth={true}
                                    width={"452px"}
                                    height={"359px"}
                                    type={"six"}
                                    setItem={mainItem}
                                  />
                                  {/* <TwoBottomImg
                                src={
                                  setItem[1]?.imageUrlList &&
                                  MEDIA_URL + setItem[1]?.imageUrlList[0]
                                }
                                isWidth={true}
                              /> */}
                                </TwoBottomImgWrapper>
                              </TwoBottomSide>
                            </TwoRightSide>
                          </TwoContainer>
                        </ContentWrapper>
                      );
                    } else if (mainItem?.length === 6) {
                      return (
                        <ContentWrapper key={`local-six-${index}-${localIndex}`}>
                          <SixContainer>
                            <SixTopSide>
                              <SixImgWrapper>
                                {/*<BlurredSixBackground*/}
                                {/*  backImg={*/}
                                {/*    setItem[0]?.imageUrlList &&*/}
                                {/*    MEDIA_URL + setItem[0]?.imageUrlList[0]*/}
                                {/*  }*/}
                                {/*/>*/}

                                <FullImageList
                                  imagelist={mainItem[0]?.imageUrlList}
                                  isWidth={true}
                                  width={"620px"}
                                  height={"520px"}
                                  type={"six"}
                                  setItem={mainItem}
                                  num={0}
                                  setIntervalTime={setIntervalTime}
                                />
                                {/* <SixImg
                              src={
                                setItem[0]?.imageUrlList &&
                                MEDIA_URL + setItem[0]?.imageUrlList[0]
                              }
                              isWidth={true}
                            /> */}
                              </SixImgWrapper>
                              <SixImgWrapper>
                                {/*<BlurredSixBackground*/}
                                {/*  backImg={*/}
                                {/*    setItem[1]?.imageUrlList &&*/}
                                {/*    MEDIA_URL + setItem[1]?.imageUrlList[0]*/}
                                {/*  }*/}
                                {/*/>*/}

                                <FullImageList
                                  imagelist={mainItem[1]?.imageUrlList}
                                  isWidth={true}
                                  width={"620px"}
                                  height={"520px"}
                                  type={"six"}
                                  setItem={mainItem}
                                  num={1}
                                  setIntervalTime={setIntervalTime}
                                />
                                {/* <SixImg
                              src={
                                setItem[1]?.imageUrlList &&
                                MEDIA_URL + setItem[1]?.imageUrlList[0]
                              }
                              isWidth={true}
                            /> */}
                              </SixImgWrapper>
                              <SixImgWrapper>
                                <FullImageList
                                  imagelist={mainItem[2]?.imageUrlList}
                                  isWidth={true}
                                  width={"620px"}
                                  height={"520px"}
                                  type={"six"}
                                  setItem={mainItem}
                                  num={2}
                                  setIntervalTime={setIntervalTime}
                                />
                              </SixImgWrapper>
                            </SixTopSide>
                            <SixBottomSide>
                              <SixImgWrapper>
                                <FullImageList
                                  imagelist={mainItem[3]?.imageUrlList}
                                  isWidth={true}
                                  width={"620px"}
                                  height={"520px"}
                                  type={"six"}
                                  setItem={mainItem}
                                  num={3}
                                  setIntervalTime={setIntervalTime}
                                />
                              </SixImgWrapper>
                              <SixImgWrapper>
                                {/*<BlurredSixBackground*/}
                                {/*  backImg={*/}
                                {/*    setItem[4]?.imageUrlList &&*/}
                                {/*    MEDIA_URL + setItem[4]?.imageUrlList[0]*/}
                                {/*  }*/}
                                {/*/>*/}
                                {/* <SixImg
                              src={
                                setItem[4]?.imageUrlList &&
                                MEDIA_URL + setItem[4]?.imageUrlList[0]
                              }
                              isWidth={true}
                            /> */}

                                <FullImageList
                                  imagelist={mainItem[4]?.imageUrlList}
                                  isWidth={true}
                                  width={"620px"}
                                  height={"520px"}
                                  type={"six"}
                                  setItem={mainItem}
                                  num={4}
                                  setIntervalTime={setIntervalTime}
                                />
                              </SixImgWrapper>
                              <SixImgWrapper>
                                {/*<BlurredSixBackground*/}
                                {/*  backImg={*/}
                                {/*    setItem[5]?.imageUrlList &&*/}
                                {/*    MEDIA_URL + setItem[5]?.imageUrlList[0]*/}
                                {/*  }*/}
                                {/*/>*/}

                                <FullImageList
                                  imagelist={mainItem[5]?.imageUrlList}
                                  isWidth={true}
                                  width={"620px"}
                                  height={"520px"}
                                  type={"six"}
                                  setItem={mainItem}
                                  num={5}
                                  setIntervalTime={setIntervalTime}
                                />
                                {/* <SixImg
                              src={
                                setItem[5]?.imageUrlList &&
                                MEDIA_URL + setItem[5]?.imageUrlList[0]
                              }
                              isWidth={true}
                            /> */}
                              </SixImgWrapper>
                            </SixBottomSide>
                          </SixContainer>
                        </ContentWrapper>
                      );
                    }

                  })
                });
              }
            })}
        </Slider>
      </Page>

      <BottomBar>{`${userInfo?.store ? userInfo?.store : ''} ${userInfo?.address ? '/ ' + userInfo?.address : ''} ${userInfo?.detailAddress ? userInfo?.detailAddress : ''} ${userInfo?.phone ? '/ ' +  userInfo?.phone : ''} ${userInfo?.realestateRegistrationNumber ? '/ ' +  userInfo?.realestateRegistrationNumber : ''} ${userInfo?.name ? '/ ' + userInfo?.name : ''}` }</BottomBar>
    </div>
  );
};

const fadeIn = keyframes`
    from {
        opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


const BottomBar = styled.div`
    width: 100%;
    height: 70px;
    background-color: #000000;
    position: fixed;
    bottom: 0;
    z-index: 9999999;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 20px;
    color: #b3b2b2;
    font-weight: 800;
`

const Page = styled.div`
  //display: flex;
  //  justify-content: center;
  //  align-items: center;
  //  background-color: white;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullImage = styled.img`
  height: 100vh;
  position: relative;
`;

const FullLocalImageWrapper = styled.div`
  width: 1920px;
  height: 1080px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const BlurredLocalFullBackground = styled.div<{ backImg }>`
  width: 1920px;
  height: 1080px;
  background-image: ${(props) => `url(${props.backImg})`};
  background-position: center;
  filter: blur(20px);
  position: absolute;
`;

const TwoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 1920px;
  height: 1080px;
`;

const TwoTopImgWrapper = styled.div`
  width: 923px;
  height: 662px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 6px solid #000000;
  overflow: hidden;
`;

const TwoBottomImgWrapper = styled.div`
  width: 456px;
  height: 359px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 6px solid #000000;
  overflow: hidden;
`;

const TwoLeftSide = styled.div`
  position: relative;
  left: 0;
`;

const TwoRightSide = styled.div``;

const BlurredBackground = styled.div<{ backImg? }>`
  width: 923px;
  height: 648px;
  background-image: ${(props) => `url(${props.backImg})`};
  background-position: center;
  filter: blur(20px);
  position: absolute;
`;

const BlurredBottomBackground = styled.div<{ backImg? }>`
  width: 452px;
  height: 359px;
  background-image: ${(props) => `url(${props.backImg})`};
  background-position: center;
  filter: blur(20px);
  position: absolute;
`;

const TwoTopImg = styled.img<{ isWidth? }>`
  width: ${(props) => (props.isWidth ? "923px" : "")};
  height: ${(props) => (props.isWidth ? "" : "662px")};
  position: relative;
`;

const TwoBottomSide = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 8px;
`;

const TwoBottomImg = styled.img<{ isWidth? }>`
  width: ${(props) => (props.isWidth ? "452px" : "")};
  height: ${(props) => (props.isWidth ? "" : "359px")};
  position: relative;
`;

const SixContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  width: 1920px;
  height: 1080px;
`;

const SixTopSide = styled.div`
  display: flex;
  gap: 5px;
`;

const SixBottomSide = styled.div`
  display: flex;
  gap: 5px;
`;

const SixImgWrapper = styled.div`
  width: 620px;
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 6px solid #000000;
  overflow: hidden;
`;

const SixImg = styled.img<{ isWidth? }>`
  width: ${(props) => (props.isWidth ? "620px" : "")};
  height: ${(props) => (props.isWidth ? "" : "520px")};
  position: relative;
`;

const BlurredSixBackground = styled.div<{ backImg? }>`
  width: 620px;
  height: 520px;
  background-image: ${(props) => `url(${props.backImg})`};
  background-size: cover;
  filter: blur(20px);
  position: absolute;
`;

const FullContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1920px;
  height: 1080px;
`;

const FullTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 983px;
`;

const FullTitle = styled.div`
  font-weight: 700;
  font-size: 110px;
  line-height: 110px;
  display: flex;
  gap: 20px;
  margin-bottom: 93px;
`;

const FullColorTitle = styled.div`
  color: #f33636;
`;

const FullAddress = styled.div`
  font-weight: 800;
  font-size: 60px;
  line-height: 45.4px;
  margin-bottom: 42px;
`;

const FullPrice = styled.div`
  font-weight: 800;
  font-size: 80px;
  line-height: 90.8px;
  color: #125de6;
  margin-bottom: 38px;
`;

const FullLabel = styled.div`
  font-weight: 700;
  font-size: 60px;
  line-height: 70.59px;
  color: #000000cc;
  display: flex;
  gap: 20px;
  white-space: pre-wrap;
`;

const FullValue = styled.div`
  font-weight: 800;
  color: #000000;
`;

const FullDesc = styled.div`
  font-weight: 700;
  font-size: 60px;
  line-height: 61.78px;
  color: #000000cc;
  margin-top: 93px;
  white-space: pre-wrap;
  text-align: center;
`;

const FullImageWrapper = styled.div`
  width: 50%;
  height: 975px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
    
`;

const BlurredFullBackground = styled.div<{ backImg }>`
  width: 863px;
  height: 975px;
  background-image: ${(props) => `url(${props.backImg})`};
  background-position: center;
  filter: blur(20px);
  position: absolute;
`;

const FullSaleImage = styled.img`
  width: 100%;
  height: 100%;
  position: relative;
`;

// 2면

const TwoTopSide = styled.div`
  display: flex;
    justify-content: space-between;
  border: 6px solid #222222;
  margin-bottom: 5px;
`;

const TwoSaleBottomSide = styled.div`
  display: flex;
  gap: 5px;
`;

const TwoTopTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 478px;
`;

const TwoTopTitle = styled.div<{ textlength? }>`
  font-weight: 700;
  font-size: ${(props) => (props?.textlength < 5 ? "64px" : "50px")};
  line-height: ${(props) => (props?.textlength < 5 ? "64px" : "50px")};
  display: flex;
  gap: 10px;
  margin-bottom: 56px;
`;

const TwoTopColorTitle = styled.div`
  color: #f33636;
  word-break: break-all;
`;

const TwoTopAddress = styled.div`
  font-weight: 800;
  font-size: 35px;
  line-height: 27.24px;
  margin-bottom: 21.76px;
  word-break: break-all;
`;

const TwoTopPrice = styled.div`
  font-weight: 800;
  font-size: 50px;
  line-height: 56.75px;
  color: #125de6;
  margin-bottom: 23.31px;
`;

const TwoTopLabel = styled.div`
  font-weight: 700;
  font-size: 35px;
  line-height: 37.24px;
  color: #000000cc;
  display: flex;
  gap: 11.21px;
  white-space: pre-wrap;
`;

const TwoTopValue = styled.div`
  font-weight: 800;
  color: #000000;
`;

const TwoTopDesc = styled.div`
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  color: #000000cc;
  margin-top: 29px;
  white-space: pre-wrap;
  text-align: center;
`;

const TwoTopSaleImgWrapper = styled.div`
  width: 463px;
  height: 610px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const TwoBottomTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 467px;
  height: 389px;
  border: 6px solid #222222;
`;

const TwoBottomTitle = styled.div<{ textlength? }>`
  font-weight: 700;
  font-size: ${(props) => (props?.textlength < 5 ? "58px" : "48px")};
  line-height: ${(props) => (props?.textlength < 5 ? "58px" : "48px")};
  display: flex;
  gap: 10px;
  margin-bottom: 24px;
`;

const TwoBottomColorTitle = styled.div`
  color: #f33636;
`;

const TwoBottomAddress = styled.div`
  font-weight: 800;
  font-size: 34px;
  line-height: 35.24px;
  margin-bottom: 21.76px;
`;

const TwoBottomPrice = styled.div`
  font-weight: 800;
  font-size: 42px;
  line-height: 47.67px;
  color: #125de6;
  margin-bottom: 16px;
`;

const TwoBottomLabel = styled.div`
  font-weight: 700;
  font-size: 34px;
  line-height: 35.7px;
  color: #000000cc;
  display: flex;
  gap: 8px;
  margin-bottom: 8.52px;
  white-space: pre-wrap;
`;

const TwoBottomValue = styled.div`
  font-weight: 800;
  color: #000000;
`;

const BlurredSaleBackground = styled.div<{ backImg? }>`
  width: 426px;
  height: 610px;
  background-image: ${(props) => `url(${props.backImg})`};
  background-position: center;
  filter: blur(20px);
  position: absolute;
`;

const TwoTopSaleImg = styled.img<{ isWidth? }>`
  width: 100%;
  height: 100%;
  position: relative;
`;

const SixTextContainer = styled.div<{ zoom? }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 620px;
  height: 520px;
  border: 6px solid #222222;

  & > div {
    zoom: ${(props) => props.zoom || 1};
  }
`;

const SixTitle = styled.div<{ textlength? }>`
  font-weight: 700;
  font-size: ${(props) => (props?.textlength < 5 ? "78px" : "60px")};
  line-height: ${(props) => (props?.textlength < 5 ? "78px" : "60px")};
  display: flex;
  gap: 12px;
  margin-bottom: ${(props) => (props?.textlength < 5 ? "48px" : "35px")};
`;

const SixColorTitle = styled.div`
  color: #f33636;
`;

const SixAddress = styled.div`
  font-weight: 800;
  font-size: 46px;
  line-height: 49.51px;
  margin-bottom: 34px;
`;

const SixPrice = styled.div`
  font-weight: 800;
  font-size: 56px;
  line-height: 63.56px;
  color: #125de6;
  margin-bottom: 29px;
`;

const SixLabel = styled.div<{ textlength? }>`
  font-weight: 700;
  font-size: ${(props) => (props?.textlength < 30 ? "46px" : "35px")};
  line-height: ${(props) => (props?.textlength < 30 ? "49.51px" : "37.51px")};
  //line-height: 49.51px;
  color: #000000cc;
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
  white-space: pre-wrap;
`;

const SixValue = styled.div`
  font-weight: 800;
  color: #000000;
`;
