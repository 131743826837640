import React, {useEffect, useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {AdminInquireTable} from "./AdminInquireTable";
import styled from "styled-components";
import NavBar from "../../components/NavBar";
import {DeskTopCalVW, media} from "../../../utils/CalVW";
import {HomeOneToOneDetail} from "./blocks/HomeOneToOneDetail";
import {useHomeNoticeList} from "../../../HomeTown/hooks/Admin/AdminNotice/GetHomeNoticeList";
import {useHomeInquireList} from "../../../HomeTown/hooks/Admin/AdminInquire/GetHomeInquireList";
import {AdminInquireFilter} from "./AdminInquireFilter";
import {useUserInfo} from "../../../HomeTown/hooks/Auth/GetUserInfo";


export const HomeInquire = () => {
  //필터링
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [date, setDate] = useState('');
  const [type, setType] = useState<any>(3);
  const [searchWord, setSearchWord] = useState('');
  const [order, setOrder] = useState<'latest' | 'oldest'>('latest');

  // 유틸리티
  const [isSelectItem, setIsSelectItem] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isSendMessage, setIsSendMessage] = useState(false);
  const [isOTOModal, setIsOTOModal] = useState(false);


  // 데이터 조회
  const { userInfo } = useUserInfo();
  const {
    homeInquireList,
    mutateHomeInquireList, total, isLoading
  } = useHomeInquireList(page, pageSize, date, type, searchWord, order);

    // useEffect(() => {
    //   console.log('homeInquireList', homeInquireList)
    // }, [])

  return (
    <Container>
      <NavBar />

      <Display style={{boxShadow: 'none', padding: '0 11%' }}>

        <Title>Q&A</Title>

        <RegistSection>
          {/*<SelectCategory>*/}
          {/*  <SelectItem onClick={() => setCategoryItem('수신내역')} Active={categoryItem === '수신내역'}>수신내역</SelectItem>*/}
          {/*  <SelectItem onClick={() => setCategoryItem('발신내역')} Active={categoryItem === '발신내역'}>발신내역</SelectItem>*/}
          {/*</SelectCategory>*/}

          <RegistSaleButton onClick={() => setIsOTOModal(true)}>문의하기</RegistSaleButton>
        </RegistSection>


        {userInfo?.is_admin === 1 &&
          <AdminInquireFilter
            setSearchWord={setSearchWord}
            setOrder={setOrder}
            order={order}
            setType={setType}
            mutateAdminInquiryList={mutateHomeInquireList}

            setIsSelectItem={setIsSelectItem}
            isSelectItem={isSelectItem}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        }

        <AdminInquireTable
          adminInquiryList={homeInquireList}
          mutateAdminInquiryList={mutateHomeInquireList}
          setIsSendMessage={setIsSendMessage}
          isSendMessage={isSendMessage}
          inquiryTotal={total}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          isLoadingInquiry={isLoading}

          setIsSelectItem={setIsSelectItem}
          isSelectItem={isSelectItem}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          isAllChecked={isAllChecked}
          setIsAllChecked={setIsAllChecked}
        />
      </Display>

      {isOTOModal && <HomeOneToOneDetail setIsOTOModal={setIsOTOModal} mutateAgentInquireList={mutateHomeInquireList}/>}
    </Container>
  )
}

const Title = styled.h2`
    font-size: 3rem;
    font-weight: 700;
    color: #212121;
    line-height: 60px;
    text-align: center;
    margin-top: 90px;
    margin-bottom: 20px;

    @media ${media.desktop} {
        font-size: ${DeskTopCalVW(48)};
        line-height: ${DeskTopCalVW(60)};
    }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RegistSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;

    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`


const SelectCategory = styled.div`
    display: flex;
    gap: 20px;
`

const SelectItem = styled.div<{ Active: boolean }>`
    font-weight: ${(props) => props.Active ? '700' : '500'};
    font-size: 24px;
    line-height: 28.64px;
    font-family: Pretendard, sans-serif;
    color: ${(props) => props.Active ? '#2E2559' : '#00000066'};
    text-decoration: ${(props) => props.Active ? 'underline' : 'none'};
    cursor: pointer;
`
