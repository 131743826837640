import useSWR from 'swr'
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";
import {useEffect} from "react";

interface ResponseTypes {
	list: any[];
	total: number;
}

const M_KEY = '/board/inquiry/home/list'

const fetchKey = (page, maxData, date, type, searchWord, order) =>
	`${M_KEY}?${page && 'page=' + page}${maxData && '&maxData=' + maxData}${date && '&date=' + date}${type > 0 ? '&type=' + type : ''}${searchWord && '&searchWord=' + searchWord}${order && '&order=' + order}`;

export function useHomeInquireList(page, maxData, date, type, searchWord, order) {
	const { data: token } = useToken();

	const KEY = fetchKey(page, maxData, date, type, searchWord, order);


	const fetcher = () => fetchAPI(KEY);
	const { data, mutate, isLoading } = useSWR(`${KEY}`, fetcher, { revalidateOnFocus: true });

	return { homeInquireList: data?.list, mutateHomeInquireList: mutate, total: data?.total, isLoading };
}

async function fetchAPI(KEY:string): Promise<ResponseTypes> {
	try {
		const response = await API.get(`${KEY}`, '', {});
		return {list: response.result?.result?.list || [], total: response.result?.result?.totalCount}
	} catch (error) {
		console.error('Failed to fetch Home Inquire List:', error);
		return { list: [], total: 0 };
	}
}