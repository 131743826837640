import styled from 'styled-components';
import logo from '../../assets/images/logo.png';
import {useNavigate} from "react-router-dom";
import {useToken} from "../../../app/hooks";
import {DeskTopCalVW, media} from "../../../utils/CalVW";

const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 80px;
  min-height: 80px;
  border-bottom: 1px solid #dbdbdb;
  padding-left: 40px;
  padding-right: 60px;

    @media ${media.desktop} {
        height: ${DeskTopCalVW(80)};
        min-height: ${DeskTopCalVW(80)};
        padding-left: ${DeskTopCalVW(40)};
        padding-right: ${DeskTopCalVW(60)};
    }
`;

const Logo = styled.button`
  width: 148px;
  height: 34px;
  background-color: transparent;
    border: none;
    
     @media ${media.desktop} {
         width: ${DeskTopCalVW(148)};
         height: ${DeskTopCalVW(34)};
    }

  img {
    width: 100%;
    height: 100%;
  }
`;

const MenuList = styled.ul`
  display: flex;
  align-items: center;
  gap: 36px;

    @media ${media.desktop} {
        gap: ${DeskTopCalVW(36)};
    }
`;

const MenuItem = styled.li`
  font-size: 1.25rem;
  cursor: pointer;

    @media ${media.desktop} {
        font-size: ${DeskTopCalVW(20)};
    }
`;

function NavBar() {
  const navigate = useNavigate();
  const {data:token, mutate} = useToken()

  return (
    <NavBarContainer>
      <Logo
        onClick={() => {
          navigate('/');
        }}
      >
        <img src={logo} alt='logo' />
      </Logo>
      <MenuList>
        <MenuItem
          onClick={() => {
            navigate('/');
          }}
        >메인</MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/method');
          }}
        >광고방식</MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/admin/login');
          }}
        >회원가입/로그인</MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/notice');
          }}
        >공지사항</MenuItem>

        <MenuItem
          onClick={() => {
            navigate('/QnA');
          }}
        >Q&A</MenuItem>

        <MenuItem onClick={() => {
          navigate('/home-introduce');
        }}>디지털광고물 제작업체 소개</MenuItem>

        {/*{token &&*/}
        {/*  <MenuItem onClick={() => {*/}
        {/*  mutate(null)*/}
        {/*}}>로그아웃</MenuItem>}*/}

      </MenuList>
    </NavBarContainer>
  );
}

export default NavBar;
