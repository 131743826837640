import useSWR from "swr";
import { useToken } from "../../../../app/hooks";
import API from "../../../../utils/api";

const KEY = "/agent/ad/list/summary"; // 광고목록 요약

export function useAgentADSummaryList() {
	const { data: token } = useToken();
	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { agentADSummaryList: data, mutateAgentADSummaryList: mutate };
}

async function fetchAPI(KEY: string, token: any) {
	try {
		const response = await API.get(`${KEY}`, token + "", {});
		return response.result?.result?.list || [];
	} catch (error) {
		console.error("Failed to fetch Agent AD Summary List:", error);
		return [];
	}
}
