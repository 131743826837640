import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Checkbox } from "antd";
import { MEDIA_URL } from "../../../../app/constants/APIKEYS";

interface PropsType {
	setRealEstateId?: React.Dispatch<React.SetStateAction<any>>;
	setAgentIds?: React.Dispatch<React.SetStateAction<any>>;
	address?: string;
	agentId?: number;
	businessHours?: string;
	imageUrl?: string;
	mediaInfo?: string;
	store?: string;
	mediaType?: string;
	no?: number;
}

export const MediaItem = ({ setRealEstateId, agentId, address, businessHours, imageUrl, mediaInfo, store, mediaType, no }: PropsType) => {
	return (
		<Atom>
			<IdText>{no}</IdText>
			<TextWrapper>
				<StoreText onClick={() => setRealEstateId && setRealEstateId(agentId)}>{store || "등록되지 않은 상호"}</StoreText>
				<AddressText>{address || "등록되지 않은 주소"}</AddressText>
				<SubInfoText>
					{`${businessHours ? businessHours?.slice(0, 2) + "시" + "~" + businessHours?.slice(3, 5) + "시" : ""}  ${
						mediaInfo ? "| " + mediaInfo : ""
					}  ${mediaType ? "| " + mediaType : ""}`}
				</SubInfoText>
			</TextWrapper>

			{imageUrl && <PlaceImg src={MEDIA_URL + imageUrl} />}
		</Atom>
	);
};

const Atom = styled.div`
	display: flex;
	align-items: center;
	padding: 20px;
	//border-bottom: 1px solid #0000000D;
`;

const IdText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 16.71px;
	color: #00000099;
	margin-right: 21px;
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 7px;
	margin-right: 101px;
`;

const StoreText = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19.09px;
	text-decoration: underline;
	cursor: pointer;
`;

const AddressText = styled.div`
	font-weight: 500;
	font-size: 14px;
	line-height: 16.71px;
`;

const SubInfoText = styled.div`
	font-weight: 500;
	font-size: 14px;
	line-height: 16.71px;
	color: #000000b2;
`;

const PlaceImg = styled.img`
	width: 125px;
`;
