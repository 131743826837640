import useSWR from "swr";
import { useToken } from "../../../../app/hooks";
import API from "../../../../utils/api";
import { useEffect } from "react";

interface ResponseTypes {
	list: any[];
	total: number;
}

const M_KEY = "/admin/settlement"; // 광고목록 조회

const fetchKey = (itemId: any) => `${M_KEY}${itemId ? `/${itemId}` : ""}`;

export function useAdminSettlementList(itemId: any) {
	const { data: token } = useToken();

	const KEY = fetchKey(itemId);

	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate, isLoading } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { adminSettlementList: data?.list.map((v, i) => ({ ...v, no: i + 1 })), mutateSettlementList: mutate, total: data?.total as any, isLoading };
}

async function fetchAPI(KEY: string, token: any): Promise<ResponseTypes> {
	try {
		const response = await API.get(`${KEY}`, token + "", {});
		console.log(response);
		return { list: response.result?.result?.list || [], total: response.result?.result?.totalCount ?? response.result?.result?.total };
	} catch (error) {
		console.error("Failed to fetch Admin Settlement List:", error);
		return { list: [], total: 0 };
	}
}
