import React, {useState, useEffect} from 'react'
import {Table, Checkbox, message} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import {AdminInquireDetail} from "./blocks/AdminInquireDetail";
import {AdminMessageDetail} from "./blocks/AdminMessageDetail";
import {useUserInfo} from "../../../HomeTown/hooks/Auth/GetUserInfo";
import {PasswordModal} from "./blocks/PasswordModal";


export const AdminInquireTable = ({
                                    adminInquiryList,
                                    mutateAdminInquiryList,
                                    isSendMessage,
                                    setIsSendMessage,
                                    inquiryTotal,
                                    page,
                                    setPage,
                                    pageSize,
                                    setPageSize,
                                    isLoadingInquiry,
                                    setIsSelectItem,isSelectItem, selectedRows, setSelectedRows, isAllChecked, setIsAllChecked
                                  }) => {
  const [itemId, setItemId] = useState(null);
  const [passwordId, setPasswordId] = useState(null); // 패스워드 비교를 위한 id
  const [isPasswordOpen, setIsPasswordOpen] = useState(false);
  const [postPassword, setPostPassword] = React.useState<any>('');
  const { userInfo } = useUserInfo();


  useEffect(() => {
    const allSelected = adminInquiryList?.length > 0 && adminInquiryList?.every(it => selectedRows?.includes(it.postId));
    setIsAllChecked(allSelected);
  }, [selectedRows, adminInquiryList]);


  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows?.filter(rowId => rowId !== id));
    }
  };


  const handleSelectAllChange = (e) => {
    setIsAllChecked(e.target.checked);
    const ids = adminInquiryList?.map(db => db.postId);
    setSelectedRows(e.target.checked ? ids : []);
  };

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  const customerColumns = [
    {
      dataIndex: 'postId',
      title: isSelectItem ?
        ( <Checkbox
          checked={isAllChecked}
          onChange={handleSelectAllChange}
        />)
        :
        'No'
      ,
      render: (value, data, index) => {
        return (
          isSelectItem ?
            (<div className='checkbox' style={{justifyContent: 'center', display: 'flex'}}>
              <Checkbox checked={selectedRows?.includes(data.postId)} key={data?.postId} onChange={(e) => handleCheckboxChange(e, data?.postId)} />
            </div>)
            :
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>


        )
      }
    },
    {
      dataIndex: 'title',
      title: '제목',
      render: (value, data, index) => {
        return (
          <div
            className='No.'
            style={{justifyContent: 'center', display: 'flex', textDecoration: 'underline', cursor: 'pointer'}}
            onClick={() => {
              if(data?.is_secret === 1){
                if(userInfo?.is_admin === 1){
                  setItemId(data?.postId)
                } else {
                  setIsPasswordOpen(true);
                  setPasswordId(data?.postId);
                  setPostPassword(data?.post_password);
                }
              }else{
                setItemId(data?.postId)
              }
            }}
          >{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'postWriter',
      title: '작성자',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '첨부파일',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{data?.fileList?.length || '파일없음'}</div>
        )
      }
    },
    {
      dataIndex: 'createdAt',
      title: '등록일',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'is_secret',
      title: '비밀글여부',
      render: (value, data) => {
        return (
          <div className='date'
               style={{justifyContent: 'center', display: 'flex'}}>{value === 0 ? '공개글' : '비밀글'}</div>
        )
      }
    },
    {
      dataIndex: 'answerStatus',
      title: '답변',
      render: (value, data) => {
        return (
          <div className='date'
               style={{justifyContent: 'center', display: 'flex'}}>{value === 0 ? '작성필요' : '작성완료'}</div>
        )
      }
    }
  ];



  return (
    <DBFilterWrapper style={{boxShadow: 'none', padding: 0}}>

      <StyleTable columns={customerColumns}
                  dataSource={adminInquiryList}
                  bordered={true}
                  loading={isLoadingInquiry}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: inquiryTotal,
                  }}
                  onChange={handleTableChange}
      />

      {itemId !== null &&
        <AdminInquireDetail setItemId={setItemId} itemId={itemId} mutateAdminInquiryList={mutateAdminInquiryList}/>}

      {isPasswordOpen &&
        <PasswordModal setIsPasswordOpen={setIsPasswordOpen} isPasswordOpen={isPasswordOpen} mutateAdminInquiryList={mutateAdminInquiryList} passwordId={passwordId} setItemId={setItemId} postPassword={postPassword} />}

      {/*{messageItemId !== null && <AdminMessageDetail setMessageItemId={setMessageItemId} messageItemId={messageItemId}*/}
      {/*                                               setIsSendMessage={setIsSendMessage} isSendMessage={isSendMessage}*/}
      {/*                                               mutateAdminMessageList={mutateAdminMessageList}/>}*/}
      {/*{isSendMessage && <AdminMessageDetail setMessageItemId={setMessageItemId} messageItemId={messageItemId}*/}
      {/*                                      setIsSendMessage={setIsSendMessage} isSendMessage={isSendMessage}*/}
      {/*                                      mutateAdminMessageList={mutateAdminMessageList}/>}*/}

    </DBFilterWrapper>
  )
}

export default React.memo(AdminInquireTable);

const ColumnHeader = styled.div`

`

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #F4F2FF;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
    }
`
