import useSWR from 'swr'
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";
import {useEffect} from "react";


const M_KEY = '/board/inquiry/home/list' // 광고상세조회

const fetchKey = (id) =>
	`${M_KEY}/${id}`;

export function useHomeInquireInfo(id) {
	const { data: token } = useToken();

	const KEY = fetchKey(id);


	const fetcher = () => fetchAPI(KEY);
	const { data, mutate } = useSWR(`${KEY}`, fetcher, { revalidateOnFocus: true });

	return { homeInquireInfo: data, mutateHomeInquireInfo: mutate};
}

async function fetchAPI(KEY:string)  {
	try {
		const response = await API.get(`${KEY}`, '', {});
		return response.result?.result;
	} catch (error) {
		console.error('Failed to fetch Home Inquire Info:', error);
		return { list: [], total: 0 };
	}
}