import useSWR from "swr";
import { useToken } from "../../../../app/hooks";
import API from "../../../../utils/api";
import { useEffect } from "react";

interface ResponseTypes {
	list: any[];
	total: {};
}

const M_KEY = "/agent/refund"; // 정산목록 조회

const fetchKey = (id) => `${M_KEY}/${id}`;

export function useAgentRefundDetailList(id) {
	const { data: token } = useToken();

	const KEY = fetchKey(id);

	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { agentRefundDetailList: data?.list.map((v, i) => ({ ...v, no: i + 1 })), mutateAgentRefundDetailList: mutate, total: data?.total };
}

async function fetchAPI(KEY: string, token: any): Promise<ResponseTypes> {
	try {
		const response = await API.get(`${KEY}`, token + "", {});
		return { list: response.result?.result?.list || [], total: response.result?.result?.total };
	} catch (error) {
		console.error("Failed to fetch Agent Refund Detail List:", error);
		return { list: [], total: {} };
	}
}
