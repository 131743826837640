import * as React from 'react'
import styled from "styled-components";
import LoginForm from "../../../organisms/Auth/Forms/LoginForm";
import {HomeTownSignUpModal} from "../../../organisms/Auth/Forms/HomeTownSignUpModal";
import {HomeTownCustomerSignUpModal} from "../../../organisms/Auth/Forms/HomeTownCustomerSignUpModal";
import {HomeTownRealEstateSignUpModal} from "../../../organisms/Auth/Forms/HomeTownRealEstateSignUpModal";
import {HomeTownFindInfoModal} from "../../../organisms/Auth/Forms/HomeTownFindInfoModal";
import NavBar from "../../../../HomePage/components/NavBar";


export function LoginPage() {
	const [isSignUpModalOpen, setIsSignUpModalOpen] = React.useState(false);
	const [isCustomerSignUpModalOpen, setIsCustomerSignUpModalOpen] = React.useState(false);
	const [isRealEstateSignUpModalOpen, setIsRealEstateSignUpModalOpen] = React.useState(false);
	const [isFindInfoModal, setIsFindInfoModal] = React.useState(false);

	return (
		<Container>
			<NavBar />

			<ContentContainer>

				<LoginForm setIsSignUpModalOpen={setIsSignUpModalOpen} setIsFindInfoModal={setIsFindInfoModal} />

				{isSignUpModalOpen &&
					<HomeTownSignUpModal setIsSignUpModalOpen={setIsSignUpModalOpen} setIsCustomerSignUpModalOpen={setIsCustomerSignUpModalOpen} setIsRealEstateSignUpModalOpen={setIsRealEstateSignUpModalOpen}/>
				}

				{isCustomerSignUpModalOpen &&
					<HomeTownCustomerSignUpModal setIsCustomerSignUpModalOpen={setIsCustomerSignUpModalOpen}/>
				}

				{isRealEstateSignUpModalOpen &&
					<HomeTownRealEstateSignUpModal setIsRealEstateSignUpModalOpen={setIsRealEstateSignUpModalOpen}/>
				}

				{isFindInfoModal &&
					<HomeTownFindInfoModal setIsFindInfoModal={setIsFindInfoModal}/>
				}

			</ContentContainer>
		</Container>
	)
}

const ContentContainer = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #F7F2FD;
	
	
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
