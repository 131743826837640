import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import API from "../../utils/api";
import {message} from "antd";
import {useToken} from "../../app/hooks";
import {MobileSalePropertyDetail} from "./block/MobileSalePropertyDetail";
import {useNavigate} from "react-router";
import {SalePropertyTable} from "../RealEstate/SaleProperty/SalePropertyTable";
import {useAgentSaleList} from "../hooks/Agent/SaleProperty/GetAgentSaleList";
import {useUserInfo} from "../hooks/Auth/GetUserInfo";

export const SaleImageMobile = () => {
  // 필터링 관련
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [status, setStatus] = useState(5); // 1: 대기 | 2: 광고중 | 3: 중지 | 4: 거래완료
  const [order, setOrder] = useState('latest'); // latest, oldest
  const [searchWord, setSearchWord] = useState('');

  // 유틸리티
  const [isDetailModal, setIsDetailModal] = useState(false)
  const [isSelectItem, setIsSelectItem] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const navigate = useNavigate();

  // 데이터 조회
  const { agentSaleList, mutateAgentSaleList, total, isLoading } = useAgentSaleList(page, pageSize, status, order, searchWord);
  const { userInfo, mutateUserInfo } = useUserInfo();

  // useEffect(() => {
  //   console.log('userInfo', userInfo);
  //
  //   if(userInfo?.userType !== '1') {
  //     message.error('매체주 매물 등록을 위한 페이지입니다.')
  //     navigate('/admin/login')
  //   }
  // }, [userInfo])


  return (
    <Page>

      {/*<RegistSection>*/}
      {/*  <RegistSaleButton onClick={() => {*/}
      {/*    checkSale();*/}
      {/*  }}>매물등록</RegistSaleButton>*/}
      {/*</RegistSection>*/}

      <SalePropertyTable
        isDetailModal={isDetailModal}
        setIsDetailModal={setIsDetailModal}
        setPage={setPage}
        page={page}
        setPageSize={setPageSize}
        pageSize={pageSize}
        agentSaleList={agentSaleList}
        mutateAgentSaleList={mutateAgentSaleList}
        total={total}
        setIsSelectItem={setIsSelectItem}
        isSelectItem={isSelectItem}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
        isLoading={isLoading}
      />

      {/*{isDetailModal && <MobileSalePropertyDetail setIsDetailModal={setIsDetailModal}/>}*/}
    </Page>
  )
}

const Page = styled.div`
  width: 100%;
    padding-top: 50px;
`

const RegistSection = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-right: 10px;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard ,sans-serif;
`

