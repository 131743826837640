import React, {Suspense, useEffect, useState} from 'react'
import { Main, Wrapper } from './styles'
import SideBar from '../SideBar'
import {Route, Routes, useLocation} from 'react-router-dom'
import Home from '../Home'
import TopBar from '../../components/TopBar'
import { useMyInfo } from '../../hooks/GetMyInfo'
import { SideBarTitle } from '../SideBar/styles'
import {UserManage} from "../../../HomeTown/Admin/UserManage";
import {OperateManage} from "../OperateManage";
import {LearningManage} from "../LearningManage";
import {TosSettings} from "../TosSettings";
import {Commercial} from "../../../HomeTown/RealEstate/Commercial";
import {SaleProperty} from "../../../HomeTown/RealEstate/SaleProperty";
import {OutputManage} from "../../../HomeTown/RealEstate/OutputManage";
import {Transaction} from "../../../HomeTown/RealEstate/Transaction";
import {Calculation} from "../../../HomeTown/RealEstate/Calculation";
import {Notice} from "../../../HomeTown/RealEstate/Notice";
import {CustomerCommercial} from "../../../HomeTown/Customer/CustomerCommercial";
import {Deposit} from "../../../HomeTown/Customer/Deposit";
import {PaymentManage} from "../../../HomeTown/Admin/PaymentManage";
import {AdminCalculation} from "../../../HomeTown/Admin/AdminCalculation";
import {ContentsManage} from "../../../HomeTown/Admin/ContentsManage";
import {AdminNotice} from "../../../HomeTown/Admin/AdminNotice";
import {InquireList} from "../../../HomeTown/RealEstate/InquireList";
import {AdminInquire} from "../../../HomeTown/Admin/AdminInquire";
import {MediaList} from "../../../HomeTown/Customer/MediaList";
import {CallbackPage} from "../../../HomeTown/CallbackPage";
import {CommercialFee} from "../../../HomeTown/Admin/CommercialFee";
import {AdDetail} from "../../../HomeTown/CallbackPage/blocks/AdDetail";
import {AgentDetail} from "../../../HomeTown/CallbackPage/blocks/AgentDetail";
import {Spin} from "antd";
import {SettingAdmin} from "../../../HomeTown/Admin/SettingAdmin";
import {SelfCommercial} from "../../../HomeTown/RealEstate/SelfCommercial";
import {SettingAgent} from "../../../HomeTown/RealEstate/SettingAgent";
import {AdminDetail} from "../../../HomeTown/CallbackPage/blocks/AdminDetail";
import {GlobalFooter} from "../../../app/components/Layout/GlobalFooter";
import {CommercialRegist} from "../../../HomeTown/Admin/CommercialRegist";

export const HomeTown = () => {

	// 유틸리티
	const location = useLocation()
	const { pathname } = location
	const current = pathname.split('/admin')[1] || '';
	const [view, setView] = useState(true)
	const [adDetailModal, setAdDetailModal] = useState(false);
	const [adminDetailModal, setAdminDetailModal] = useState(false);
	const [agentDetailModal, setAgentDetailModal] = useState(false);



	// React.useEffect(() => {
	// 	console.log('pathname',pathname);
	// }, [pathname])


	return (
		<>
			<Main>
				<SideBar current={current} view={view} setAdDetailModal={setAdDetailModal} setAgentDetailModal={setAgentDetailModal} setAdminDetailModal={setAdminDetailModal} />
				<Wrapper>
					<TopBar view={view} setView={setView} />
					<Suspense fallback={<Spin size="large" />}>
						<Routes>
							{/*<Route path="/" element={<Home />} />*/}
							<Route path="/commercial" element={<Commercial />} />
							<Route path="/saleProperty" element={<SaleProperty />} />
							<Route path="/selfCommercial" element={<SelfCommercial />} />
							<Route path="/outputManage" element={<OutputManage />} />
							<Route path="/transaction" element={<Transaction />} />
							<Route path="/calculation" element={<Calculation />} />
							<Route path="/notice" element={<Notice />} />
							<Route path="/inquireList" element={<InquireList />} />
							<Route path="/customerCommercial" element={<CustomerCommercial />} />
							<Route path="/deposit" element={<Deposit />} />
							<Route path="/userManage" element={<UserManage />} />
							<Route path="/paymentManage" element={<PaymentManage />} />
							<Route path="/calculationAdmin" element={<AdminCalculation />} />
							<Route path="/contentsManage" element={<ContentsManage />} />
							<Route path="/noticeAdmin" element={<AdminNotice />} />
							<Route path="/inquireAdmin" element={<AdminInquire />} />
							<Route path="/mediaList" element={<MediaList />} />
							<Route path="/commercialFee" element={<CommercialFee />} />
							<Route path="/settingAdmin" element={<SettingAdmin />} />
							<Route path="/settingAgent" element={<SettingAgent />} />
							<Route path="/commercialRegist" element={<CommercialRegist />} />

							<Route path="/" element={<CallbackPage />} />

						</Routes>
					</Suspense>

					{/*{menu.filter((item: any) => item.url === current).length === 0 && (*/}
					{/*	<SideBarTitle>⛔접근권한이 없습니다.</SideBarTitle>*/}
					{/*)}*/}

					{adDetailModal && <AdDetail adDetailModal={adDetailModal} setAdDetailModal={setAdDetailModal} />}
					{adminDetailModal && <AdminDetail adminDetailModal={adminDetailModal} setAdminDetailModal={setAdminDetailModal} />}
					{agentDetailModal && <AgentDetail agentDetailModal={agentDetailModal} setAgentDetailModal={setAgentDetailModal} />}

				</Wrapper>
			</Main>

		</>
	)
}

