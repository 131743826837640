import React, {useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import styled from "styled-components";
import {ReactComponent as DownloadIcon} from "../assets/DownloadIcon.svg";
import {message} from "antd";
import API from "../../../../utils/api";
import {MEDIA_URL} from "../../../../app/constants/APIKEYS";
import TextArea from "antd/es/input/TextArea";
import {useToken} from "../../../../app/hooks";
import {useAgentInquireInfo} from "../../../../HomeTown/hooks/Agent/InquireList/GetAgentInquireInfo";
import {useAgentInquireAnswerInfo} from "../../../../HomeTown/hooks/Agent/InquireList/GetAgentInquireAnswerInfo";
import {useHomeInquireInfo} from "../../../../HomeTown/hooks/Admin/AdminInquire/GetHomeInquireInfo";
import { useUserInfo } from '../../../../HomeTown/hooks/Auth/GetUserInfo';

export const AdminInquireDetail = ({itemId, setItemId, mutateAdminInquiryList}) => {
    // 상태값
    const [fileList, setFileList] = React.useState<any>([]);
    const [content, setContent] = React.useState<any>('');

    //유틸리티
    const [fileInputKey, setFileInputKey] = useState(Date.now()); // 파일 초기화

    // 데이터 조회
   const {homeInquireInfo, mutateHomeInquireInfo} = useHomeInquireInfo(itemId);
   const {agentInquireAnswerInfo, mutateAgentInquireAnswerInfo} = useAgentInquireAnswerInfo(itemId);
   const {data: token} = useToken();
    const { userInfo } = useUserInfo();


    useEffect(() => {
        setFileList(agentInquireAnswerInfo?.fileList || []);
        setContent(agentInquireAnswerInfo?.content || '');
    }, [agentInquireAnswerInfo]);


    const postAnswer = () => {
        const shouldEdit = window.confirm(`답변을 작성하시겠습니까?`);
        if(shouldEdit) {
            API.post(`/board/inquiry/list/${itemId}/answer`, token + '', {
                content,
                fileList
            })
              .then((res: any) => {
                  if (res.result.success) {
                      message.success('작성되었습니다.');
                      setItemId(null);
                      mutateAdminInquiryList()
                  } else {
                      message.error(res.result.message || '실패하였습니다.')
                  }
              })
              .catch((err: any) => {
                  message.error('실패하였습니다.')
              })
        }
    }


    const UploadData = (file, fileType) => {
        if (!file) {
            message.error('파일을 선택하세요');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);


        API.postImage('POST', '/content?type=user', '', formData)
          .then((res) => {
              if (res.message === 'success') {
                  message.success('파일이 추가되었습니다.');
                  console.log(res)

                  const url = res?.result?.url;
                  const name = res?.result?.name;

                  setFileList([...fileList, {name, fileType, url}])
                  setFileInputKey(Date.now())
              } else {
                  message.error('파일이 잘못되었습니다');
              }
          })
          .catch((err) => {
              message.error('업로드 중 오류가 발생했습니다');
              console.log('Upload Data ERROR',err)
          });
    };

    // const DeleteFileListData = (name, index) => {
    //     const shouldEdit = window.confirm(`${name}을 삭제하시겠습니까?`);
    //     if (shouldEdit) {
    //         setFileList((prevFileList) => prevFileList.filter((_, i) => i !== index));
    //     }
    // };


    const handleDownload = async (url, name) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const link = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            link.href = objectUrl;
            link.style.display = 'none';
            link.download = name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(objectUrl);
        } catch (error) {
            message.error('다운로드에 실패하였습니다.');
        }
    };

    const handleDownloadAll = async (fileList) => {
        try {
            await Promise.all(
              fileList.map(file => handleDownload(MEDIA_URL + file.url, file.name))
            );
            message.success('모든 파일을 저장하였습니다.');
        } catch (error) {
            message.error('모든 파일 저장에 실패하였습니다.');
        }
    };


    return (
      <>
          <Blacked onClick={() => setItemId(null)}/>
          <ModalWrapper className={"scroll-container"}>
              <ModalHeader>
                  <ModalTitle>1:1문의 상세</ModalTitle>
                  <CloseIconSVG as={ModalCloseIcon} onClick={() => {setItemId(null)}  }/>
              </ModalHeader>
              
              <Table>
                  <tbody>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              제목
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>{homeInquireInfo?.title}</Td>
                  </TableRow>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              등록일
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>{homeInquireInfo?.createdAt}</Td>
                  </TableRow>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              첨부파일
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          <div>
                              <div style={{display: 'flex', fontWeight: '500', fontSize: 14, lineHeight: '16.71px', marginBottom: 12}}>
                                  {`${homeInquireInfo?.fileList?.length || 0}건`}
                                  <div style={{fontWeight: '300', color: '#00000066', marginLeft: 6}}>|</div>
                                  <div onClick={() => handleDownloadAll(homeInquireInfo?.fileList)} style={{fontWeight: '400', color: '#125DE6CC', textDecoration: 'underline', marginLeft: 6, cursor: 'pointer'}}>모두저장</div>
                              </div>

                              {homeInquireInfo?.fileList?.map((item, index) => (
                                <div style={{
                                    backgroundColor: '#FAFAFC',
                                    width: 592,
                                    height: 29,
                                    padding: '6px 8px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: 4
                                }}>
                                    <div>{item?.name}</div>
                                    <DownloadSVG as={DownloadIcon} onClick={() => handleDownload(MEDIA_URL + item?.url, item?.name)}/>
                                </div>
                              ))}
                          </div>
                      </Td>
                  </TableRow>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              내용
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          <div style={{minHeight: 120, display: 'flex', justifyContent: 'space-between', whiteSpace: 'pre-wrap'}}>
                              {homeInquireInfo?.content}
                          </div>
                      </Td>
                  </TableRow>
                  </tbody>
              </Table>


              {userInfo?.is_admin === 1 &&
                <>
                    <ContentsTitle>답변작성</ContentsTitle>

                    <Table>
                        <tbody>
                        <TableRow>
                            <ThGrey>
                                <ThLabel>
                                    등록일
                                </ThLabel>
                            </ThGrey>
                            <Td colSpan={3}>{agentInquireAnswerInfo?.createdAt}</Td>
                        </TableRow>
                        <TableRow>
                            <ThGrey>
                                <ThLabel>
                                    첨부파일
                                </ThLabel>
                            </ThGrey>
                            <Td colSpan={3}>
                                <div>
                                    <div style={{display: 'flex', fontWeight: '500', fontSize: 14, lineHeight: '16.71px', marginBottom: 12}}>
                                        {`${fileList?.length || 0}건`}
                                        {/*<div style={{fontWeight: '300', color: '#00000066'}}>(1.7MB) |</div>*/}
                                        <div onClick={() => handleDownloadAll(homeInquireInfo?.fileList)} style={{fontWeight: '400', color: '#125DE6CC', textDecoration: 'underline', marginLeft: 6, cursor: 'pointer'}}>모두저장</div>
                                    </div>

                                    {/*{fileList?.map((item, index) => (*/}
                                    {/*  <div style={{*/}
                                    {/*      backgroundColor: '#FAFAFC',*/}
                                    {/*      width: 592,*/}
                                    {/*      height: 29,*/}
                                    {/*      padding: '6px 8px',*/}
                                    {/*      display: 'flex',*/}
                                    {/*      justifyContent: 'space-between',*/}
                                    {/*      alignItems: 'center',*/}
                                    {/*      marginBottom: 4*/}
                                    {/*  }}>*/}
                                    {/*      <div>{item?.name}</div>*/}
                                    {/*      <DownloadSVG as={DownloadIcon} onClick={() => handleDownload(MEDIA_URL + item?.url, item?.name)}/>*/}
                                    {/*  </div>*/}
                                    {/*))}*/}

                                    <FileInput key={fileInputKey} type="file" disabled={agentInquireAnswerInfo?.createdAt} onChange={({target: {files}}) => {
                                        files?.length && UploadData(files[0], 0);

                                    }}/>
                                    {fileList?.map((item: any, index: number) => (
                                      <DescText>
                                          {item?.name}
                                          <DownloadSVG as={DownloadIcon} style={{marginLeft: 10}} onClick={() => handleDownload(MEDIA_URL + item?.url, item?.name)}/>
                                          <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                                              setFileList(prev => {
                                                  const newFileList = [...prev];
                                                  const prevIndex = newFileList.findIndex(i => i.urlId === item?.urlId);
                                                  if (prevIndex !== -1) {
                                                      newFileList.splice(prevIndex, 1);
                                                  }
                                                  return newFileList;
                                              });
                                          }} />
                                      </DescText>
                                    ))}
                                </div>
                            </Td>
                        </TableRow>
                        <TableRow>
                            <ThGrey>
                                <ThLabel>
                                    내용
                                </ThLabel>
                            </ThGrey>
                            <Td colSpan={3}>
                                {agentInquireAnswerInfo?.createdAt ?
                                  <div style={{
                                      minHeight: 120,
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      whiteSpace: 'pre-wrap'
                                  }}>
                                      {agentInquireAnswerInfo?.content}
                                  </div>
                                  :
                                  <TextArea value={content} onChange={e => setContent(e.target.value)}/>
                                }
                            </Td>
                        </TableRow>
                        </tbody>
                    </Table>

                    <ButtonsWrapper>
                        {/*<PrevNextText>*/}
                        {/*    <ArrowSVG as={LeftArrowIcon}/>*/}
                        {/*    이전글*/}
                        {/*</PrevNextText>*/}

                        {!agentInquireAnswerInfo?.createdAt &&
                          <CancelButton onClick={() => postAnswer()}>
                              답변 등록
                          </CancelButton>
                        }

                        {/*<PrevNextText>*/}
                        {/*    다음글*/}
                        {/*    <ArrowSVG as={RightArrowIcon}/>*/}
                        {/*</PrevNextText>*/}
                    </ButtonsWrapper>
                </>
              }



          </ModalWrapper>
      </>
    )
}


const DeleteItemIconSVG = styled.svg`
    width: 12px;
    cursor: pointer;
    margin-left: 10px;
`


const DescText = styled.div`
  font-weight: 400;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    line-height: 16.71px;
    color: #000000;
    margin-top: 8px;
    margin-bottom: 20px;
    white-space: pre-wrap;
`

const FileInput = styled.input`
    width: 100%;
    padding: 2px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 808px;
    width: 780px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Table = styled.table`
    width: 100%;
    margin-top: 8px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 20%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
    vertical-align: middle;
`;

const ThGrey = styled.th`
    padding: 10px;
    text-align: left;
    width: 20%;
    border: 1px solid #ccc;
    background-color: #F5F5F5;
    vertical-align: middle;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    
`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
`;


const ButtonsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`


const CancelButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    border: 1px solid #0000004D;
    color: #125DE6;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const DownloadSVG = styled.svg`
    cursor: pointer;
    width: 16px;
`

const PrevNextText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    font-family: Pretendard, sans-serif;
    color: #00000099;
    cursor: pointer;
`

const ArrowSVG = styled.svg`
    width: 20px;
`

const ContentsTitle = styled.div`
    width: 100%;
    margin-top: 20px;
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    color: #000000CC;
    display: flex;
    gap: 8px;
`