import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Display } from "../../../CRM/organism/Main/styles";
import { useAdminADPriceList } from "../../hooks/Admin/CommercialFee/GetAdminADPriceList";
import { useAdminAgentPriceList } from "../../hooks/Admin/CommercialFee/GetAdminAgentPriceList";
import API from "../../../utils/api";
import { message } from "antd";
import { useToken } from "../../../app/hooks";
import { numberWithCommas } from "../../../utils/ciutils";

export const CommercialFee = () => {
	// 상태값
	const [datas, setDatas] = useState<any>([]);
	const [ad1, setAd1] = useState<any>(0);
	const [ad2, setAd2] = useState<any>(0);
	const [ad3, setAd3] = useState<any>(0);
	const [agent1, setAgent1] = useState<any>(0); // 외부 + 매물
	const [agent2, setAgent2] = useState<any>(0); // 외부
	const [agent3, setAgent3] = useState<any>(0); // 매물
	const [agent10, setAgent10] = useState<any>(0); // 외부 + 매물(연)
	const [agent11, setAgent11] = useState<any>(0); // 외부 (연)
	const [agent12, setAgent12] = useState<any>(0); // 매물 (연)

	// 데이터 조회
	const { adminADPriceList, mutateAdminADPriceList } = useAdminADPriceList();
	const { adminAgentPriceList, mutateAdminAgentPriceList } = useAdminAgentPriceList();
	const { data: token } = useToken();

	useEffect(() => {
		setAd1(adminADPriceList?.find((item) => item.itemId === 1)?.price);
		setAd2(adminADPriceList?.find((item) => item.itemId === 2)?.price);
		setAd3(adminADPriceList?.find((item) => item.itemId === 3)?.price);
		setAgent1(adminAgentPriceList?.find((item) => item.itemId === 4)?.price);
		setAgent2(adminAgentPriceList?.find((item) => item.itemId === 5)?.price);
		setAgent3(adminAgentPriceList?.find((item) => item.itemId === 6)?.price);
		setAgent10(adminAgentPriceList?.find((item) => item.itemId === 10)?.price);
		setAgent11(adminAgentPriceList?.find((item) => item.itemId === 11)?.price);
		setAgent12(adminAgentPriceList?.find((item) => item.itemId === 12)?.price);
	}, [adminADPriceList, adminAgentPriceList]);

	const putAdminPrice = () => {
		const shouldEdit = window.confirm("변경하시겠습니까?");
		if (shouldEdit) {
			API.put("/admin/manage/price", token + "", {
				datas: [
					{
						itemId: 1,
						price: ad1,
					},
					{
						itemId: 2,
						price: ad2,
					},
					{
						itemId: 3,
						price: ad3,
					},
					{
						itemId: 4,
						price: agent1,
					},
					{
						itemId: 5,
						price: agent2,
					},
					{
						itemId: 6,
						price: agent3,
					},
					{
						itemId: 10,
						price: agent10,
					},
					{
						itemId: 11,
						price: agent11,
					},
					{
						itemId: 12,
						price: agent12,
					},
				],
			})
				.then((res: any) => {
					if (res.result.success) {
						message.success("변경되었습니다");
						mutateAdminADPriceList();
						mutateAdminAgentPriceList();
					} else {
						message.error(res.result.message || "실패하였습니다");
					}
				})
				.catch((err: any) => {
					message.error("실패하였습니다");
				});
		}
	};

	return (
		<Display>
			<TableTitle>
				광고주
				<ChangeFeeButton onClick={() => putAdminPrice()}>요금변경</ChangeFeeButton>
			</TableTitle>

			<Table>
				<tbody>
					<TableRow>
						<Th>
							<ThLabel>전면(12초), 2면(6초), 6면(6초)</ThLabel>
						</Th>
						<Td>
							<TdRowContents>
								<Input
									type="text"
									value={numberWithCommas(ad1)}
									onChange={(e) =>
										setAd1(
											e.currentTarget.value?.replace(
												/,/gi,
												""
											)
										)
									}
								/>
								<div
									style={{
										width: 60,
										marginLeft: 10,
										fontSize: 16,
									}}
								>
									/일
								</div>
							</TdRowContents>
						</Td>
					</TableRow>
					<TableRow>
						<Th>
							<ThLabel>전면(6초), 2면(6초), 6면(6초)</ThLabel>
						</Th>
						<Td>
							<TdRowContents>
								<Input
									type="text"
									value={numberWithCommas(ad2)}
									onChange={(e) =>
										setAd2(
											e.currentTarget.value?.replace(
												/,/gi,
												""
											)
										)
									}
								/>
								<div
									style={{
										width: 60,
										marginLeft: 10,
										fontSize: 16,
									}}
								>
									/일
								</div>
							</TdRowContents>
						</Td>
					</TableRow>
					<TableRow>
						<Th>
							<ThLabel>광고 수수료</ThLabel>
						</Th>
						<Td>
							<TdRowContents>
								<Input
									type="text"
									value={numberWithCommas(ad3)}
									onChange={(e) =>
										setAd3(
											e.currentTarget.value?.replace(
												/,/gi,
												""
											)
										)
									}
								/>
								<div
									style={{
										width: 60,
										marginLeft: 10,
										fontSize: 16,
									}}
								>
									%
								</div>
							</TdRowContents>
						</Td>
					</TableRow>
				</tbody>
			</Table>

			<TableTitle>
				매체주
				{/*<ChangeFeeButton>*/}
				{/*  요금변경*/}
				{/*</ChangeFeeButton>*/}
			</TableTitle>

			<Table>
				<tbody>
					<TableRow>
						<Th>
							<ThLabel>외부광고 + 매물광고</ThLabel>
						</Th>
						<Td>
							<TdRowContents>
								<Input
									type="text"
									value={numberWithCommas(agent1)}
									onChange={(e) =>
										setAgent1(
											e.currentTarget.value?.replace(
												/,/gi,
												""
											)
										)
									}
								/>
								<div
									style={{
										width: 60,
										marginLeft: 10,
										fontSize: 16,
									}}
								>
									/월
								</div>
							</TdRowContents>
						</Td>
					</TableRow>
					<TableRow>
						<Th>
							<ThLabel>외부광고</ThLabel>
						</Th>
						<Td>
							<TdRowContents>
								<Input
									type="text"
									value={numberWithCommas(agent2)}
									onChange={(e) =>
										setAgent2(
											e.currentTarget.value?.replace(
												/,/gi,
												""
											)
										)
									}
								/>
								<div
									style={{
										width: 60,
										marginLeft: 10,
										fontSize: 16,
									}}
								>
									/월
								</div>
							</TdRowContents>
						</Td>
					</TableRow>
					<TableRow>
						<Th>
							<ThLabel>매물광고</ThLabel>
						</Th>
						<Td>
							<TdRowContents>
								<Input
									type="text"
									value={numberWithCommas(agent3)}
									onChange={(e) =>
										setAgent3(
											e.currentTarget.value?.replace(
												/,/gi,
												""
											)
										)
									}
								/>
								<div
									style={{
										width: 60,
										marginLeft: 10,
										fontSize: 16,
									}}
								>
									/월
								</div>
							</TdRowContents>
						</Td>
					</TableRow>
					<TableRow>
						<Th>
							<ThLabel>외부광고 + 매물광고(연)</ThLabel>
						</Th>
						<Td>
							<TdRowContents>
								<Input
									type="text"
									value={numberWithCommas(agent10)}
									onChange={(e) =>
										setAgent10(
											e.currentTarget.value?.replace(
												/,/gi,
												""
											)
										)
									}
								/>
								<div
									style={{
										width: 60,
										marginLeft: 10,
										fontSize: 16,
									}}
								>
									/연
								</div>
							</TdRowContents>
						</Td>
					</TableRow>
					<TableRow>
						<Th>
							<ThLabel>외부광고(연)</ThLabel>
						</Th>
						<Td>
							<TdRowContents>
								<Input
									type="text"
									value={numberWithCommas(agent11)}
									onChange={(e) =>
										setAgent11(
											e.currentTarget.value?.replace(
												/,/gi,
												""
											)
										)
									}
								/>
								<div
									style={{
										width: 60,
										marginLeft: 10,
										fontSize: 16,
									}}
								>
									/연
								</div>
							</TdRowContents>
						</Td>
					</TableRow>
					<TableRow>
						<Th>
							<ThLabel>매물광고(연)</ThLabel>
						</Th>
						<Td>
							<TdRowContents>
								<Input
									type="text"
									value={numberWithCommas(agent12)}
									onChange={(e) =>
										setAgent12(
											e.currentTarget.value?.replace(
												/,/gi,
												""
											)
										)
									}
								/>
								<div
									style={{
										width: 60,
										marginLeft: 10,
										fontSize: 16,
									}}
								>
									/연
								</div>
							</TdRowContents>
						</Td>
					</TableRow>
				</tbody>
			</Table>
		</Display>
	);
};

const TableTitle = styled.div`
	font-weight: 600;
	font-size: 15px;
	line-height: 17.9px;
	color: #000000cc;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
	border: 1px solid #ccc;
`;

const TableRow = styled.tr`
	min-height: 40px;
`;

const Th = styled.th`
	padding: 10px;
	text-align: left;
	width: 30%;
	border: 1px solid #ccc;
	background-color: #f4f2ff;
`;

const ThLabel = styled.div`
	font-family: Pretendard, sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 16.71px;
	color: #00000099;
	display: flex;
	gap: 2px;
`;

const RequireIcon = styled.p`
	color: #f15151;
`;

const Td = styled.td`
	padding: 10px;
	text-align: left;
	width: 78%;
	border: 1px solid #ccc;
`;

const TdRowContents = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	font-weight: 400;
	font-size: 14px;
	line-height: 16.71px;
	font-family: Pretendard, sans-serif;
	color: #000000;
`;

const DescText = styled.div`
	font-weight: 400;
	font-size: 14px;
	font-family: Pretendard, sans-serif;
	line-height: 16.71px;
	color: #00000099;
	margin-top: 8px;
	margin-bottom: 20px;
	white-space: pre-wrap;
`;

const Input = styled.input`
	width: 50%;
	height: 28px;
	padding-left: 10px;
	border: 1px solid #2e2559;
	border-radius: 4px;
	display: flex;
`;

const ChangeFeeButton = styled.div`
	background-color: #2a29e6;
	color: white;
	padding: 5px;
	border-radius: 4px;
	font-size: 14px;
	cursor: pointer;
`;
