export const API_URL = "https://api.myhometown-ad.co.kr/v1"; // API URL
export const MEDIA_URL = "https://api.myhometown-ad.co.kr/"; // API URL

// export const API_URL = "http://localhost:3000/v1"; // API URL
// export const MEDIA_URL = "http://localhost:3000/"; // API URL

export const UPLOAD_API_URL = `http://125.6.38.179:3000/v1/custom/pages`;
export const UPLOAD_DEFAULT_URL = `http://125.6.38.179:3000/public/uploadImage`;

export const FIREBASE_CONFIG = {
	apiKey: "AIzaSyDWa6hlrMtPx2AvZgSR7HyYntuJ0l6XZ4A",
	authDomain: "cms-server-22b35.firebaseapp.com",
	projectId: "cms-server-22b35",
	storageBucket: "cms-server-22b35.appspot.com",
	messagingSenderId: "887007230308",
	appId: "1:887007230308:web:f5e9fe3faba37ae3c9a271",
};

export const VAPID_KEY = "BBw0ryNkmuEGY1w6ZTsTvsINJIS-mbEANi4Y826-M7zW5iGfiOika787Okrt3uF5R8mdPFzTX0HucYpVYPpunhI";
