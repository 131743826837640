import React, {startTransition, useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import {Input, message, Select, TimePicker} from "antd";
import {HomeTownRoundCheckbox} from "../../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import TextArea from "antd/es/input/TextArea";
import {TransactionSuccess} from "./TransactionSuccess";
import {CommercialEdit} from "./CommercialEdit";
import {CommercialPause} from "./CommercialPause";
import {Transaction} from "../../Transaction";
import {PreviewMonitor} from "./PreviewMonitor";
import API from "../../../../utils/api";
import {useToken} from "../../../../app/hooks";
import {useAgentSaleInfo} from "../../../hooks/Agent/SaleProperty/GetAgentSaleInfo";
import {ReactComponent as ImageDeleteIcon} from "../../../../assets/HomeTownIcon/ImageDeleteIcon.svg";
import {media, useIsMobile} from "../../../../utils/CalVW";


export const SalePropertyDetail = ({setIsDetailModal, itemId, setItemId, mutate}) => {

  // 상태값
  const [title, setTitle] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [detailAddress, setDetailAddress] = React.useState('');
  const [type, setType] = React.useState('선택');
  const [transactionType, setTransactionType] = React.useState('선택');
  const [price, setPrice] = React.useState('');
  const [size, setSize] = React.useState('');
  const [desc, setDesc] = React.useState('');
  const [fileList, setFileList] = React.useState<any>([]); // 새로 추가하기 위한 fileList
  const [localFileList, setLocalFileList] = React.useState<any>([]); // 기존의 데이터를 보여주기 위한 fileList
  const [memo, setMemo] = React.useState('');
  const [hostName, setHostName] = React.useState('');
  // const [hostTel, setHostTel] = React.useState('');
  const [deleteFileList, setDeleteFileList] = React.useState<any>([]);


  // 유틸리티
  const [isSuccessModal, setIsSuccessModal] = React.useState(false);
  const [isPauseModal, setIsPauseModal] = React.useState(false);
  const [isEditModal, setIsEditModal] = React.useState(false);
  const [isPreviewModal, setIsPreviewModal] = React.useState(false);
  const [fileInputKey, setFileInputKey] = React.useState(Date.now());

  // 데이터 조회
  const { data: token } = useToken();
  const {agentSaleInfo, mutateAgentSaleInfo} = useAgentSaleInfo(itemId);
  const isMobile = useIsMobile();

  useEffect(() => {
    console.log('agentSaleInfo', agentSaleInfo);

    setTitle(agentSaleInfo?.title);
    setAddress(agentSaleInfo?.address);
    setDetailAddress(agentSaleInfo?.detailAddress);
    setType(agentSaleInfo?.type);
    setTransactionType(agentSaleInfo?.transactionType);
    setPrice(agentSaleInfo?.price);
    setSize(agentSaleInfo?.size);
    setDesc(agentSaleInfo?.desc);
    setLocalFileList(agentSaleInfo?.fileList || []);
    setMemo(agentSaleInfo?.memo);
    setHostName(agentSaleInfo?.hostName);
  }, [agentSaleInfo]);




  const UploadData = (file, fileType) => {
    if (!file) {
      message.error('파일을 선택하세요');
      return;
    }

    console.log('file', file)


    const formData = new FormData();
    formData.append('file', file);


    API.postImage('POST', '/content?type=ad', '', formData)
      .then((res) => {
        if (res.message === 'success') {
          message.success('파일이 추가되었습니다.');
          console.log(res)

          const url = res?.result?.url;
          const name = res?.result?.name;

          setFileList(prev => [...prev, {name, fileType, url}])
          setFileInputKey(Date.now())
        } else {
          message.error('파일이 잘못되었습니다');
        }
      })
      .catch((err) => {
        message.error('업로드 중 오류가 발생했습니다');
        console.log('Upload Data ERROR',err)
      });
  };

  const RegistRealEstate = (tempStatus:number) => {
    const shouldEdit = window.confirm(tempStatus === 1 ? '임시저장하시겠습니까?' : `매물을 등록하시겠습니까?`);
    if (shouldEdit) {
      if(!title || !address || !detailAddress || !type || !transactionType || !price) {
        message.error('필수 사항을 입력해주세요.');
      } else {
        API.post('/agent/sale/list', token + '', {
          title,
          address,
          detailAddress,
          type,
          transactionType,
          price,
          size,
          desc,
          fileList,
          memo,
          hostName,
          tempStatus
        })
          .then((res: any) => {
            if (res.result.success) {
              message.success(tempStatus === 1 ? '임시저장되었습니다.' : `등록하였습니다.`);
              setIsDetailModal(false)
              mutate();
            } else {
              message.error(res.result.message || `실패하였습니다.`);
            }
          })
          .catch((err: any) => {
            message.error('실패하였습니다.')
          })
      }
    }
  }



  const putRestartCommercial = () => {
    const isConfirm = window.confirm('광고를 재개하겠습니까?');
    if(isConfirm){
      API.put(`/agent/sale/list`, token + '', {
        ids:  [itemId],
        status: 1
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success(`광고를 재개하였습니다.`);
            setIsPauseModal(false);
            setItemId(null);
            mutate();
          } else {
            message.error(res.result.message || `실패하였습니다.`);
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다.')
        })
    }
  }


  const EditRealEstate = () => {
    if(!title || !address || !detailAddress || !type || !transactionType || !price) {
      message.error('필수 사항을 입력해주세요.');
    } else {
      API.put(`/agent/sale/list/${itemId}`, token + '', {
        datas: {
          title,
          address,
          detailAddress,
          type,
          transactionType,
          price,
          size,
          desc,
          fileList,
          memo,
          hostName,
          deleteFileList,
          tempStatus:0
        }
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success(`저장하였습니다.`);
            setIsEditModal(false);
            setIsDetailModal(false);
            setItemId(null);
            mutate();
          } else {
            message.error(res.result.message || `실패하였습니다.`);
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다.')
        })
    }
  }


  const openPostcode = () => {
    new window.daum.Postcode({
      oncomplete: function (data: any) {
        const fullAddress = data?.address;
        startTransition(() => {
          setAddress(fullAddress);
          // // 좌표 변환 API 호출
          // handleGeocode(fullAddress);
        });
      }
    }).open();
  };


  return (
    <>
      <Blacked onClick={() => {setIsDetailModal(false); setItemId(null)}}/>
      <ModalWrapper className={"scroll-container"} style={{padding: isMobile ? '24px 20px' : undefined}}>
        <ModalHeader>
          <ModalTitle>{itemId ? '매물 상세' : '매물 등록'}</ModalTitle>
          <CloseIconSVG as={ModalCloseIcon} onClick={() => {setIsDetailModal(false); setItemId(null)}  }/>
        </ModalHeader>

        <ContentsTitle>{itemId ? '매물 정보' : '매물 등록'}</ContentsTitle>

        <Table>
          <tbody>
          <TableRow>
            <Th>
              <ThLabel>
                매물명
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td colSpan={3}><InputText type="text" value={title} onChange={e => setTitle(e.target.value as string)} /></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                매물주소
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td colSpan={3}>
              <TdRowContents>
                <InputText  type="text" value={address} onChange={(e)=>{
                  setAddress(e.target.value as string);
                }} />
              </TdRowContents>
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                세부주소
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td colSpan={3}><InputText  type="text" placeholder={'광고에 노출되지 않습니다.'} value={detailAddress} onChange={e => setDetailAddress(e.target.value as string)} /></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                매물종류
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              {/*<StyledSelect value={type} onChange={handleMediaChange}>*/}
              {/*  {PropertyType.map((item, index) => (*/}
              {/*    <Select.Option value={item.name}>*/}
              {/*      {item.name}*/}
              {/*    </Select.Option>*/}
              {/*  ))}*/}
              {/*</StyledSelect>*/}
              <InputText  value={type} onChange={e => setType(e.target.value as string)} placeholder={'주택, 아파트, 상가, 분양권 등'} />
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                거래구분
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              {/*<StyledSelect value={transactionType} onChange={handleTransactionChange}>*/}
              {/*  {TransactionClassification.map((item, index) => (*/}
              {/*    <Select.Option value={item.name}>*/}
              {/*      {item.name}*/}
              {/*    </Select.Option>*/}
              {/*  ))}*/}
              {/*</StyledSelect>*/}
              <InputText  value={transactionType} onChange={e => setTransactionType(e.target.value as string)} placeholder={'매매, 전세, 월세 등'} />
            </Td>
          </TableRow>

          <TableRow>
            <Th>
              <ThLabel>
                거래가
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td colSpan={3}>
              <InputText  type="text" suffix={''} value={price} onChange={e => setPrice(e.target.value as string)}/>
            </Td>
          </TableRow>
          {/*{transactionType === '매매' &&*/}
          {/*  <TableRow>*/}
          {/*    <Th>*/}
          {/*      <ThLabel>*/}
          {/*        거래가*/}
          {/*        <RequireIcon>*</RequireIcon>*/}
          {/*      </ThLabel>*/}
          {/*    </Th>*/}
          {/*    <Td colSpan={3}>*/}
          {/*      <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>*/}
          {/*        <div style={{display: 'flex', alignItems: 'center'}}>*/}
          {/*          <div style={{width: 60, fontSize: 14}}>매매가</div>*/}
          {/*          <InputText type="text" suffix={'원'} value={price} onChange={e => setPrice(e.target.value as string)}/>*/}
          {/*        </div>*/}

          {/*        <div style={{display: 'flex',  alignItems: 'center'}}>*/}
          {/*          <div style={{width: 60, fontSize: 14}}>융자금</div>*/}
          {/*          <InputText type="text" suffix={'원'} value={price} onChange={e => setPrice(e.target.value as string)}/>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </Td>*/}
          {/*  </TableRow>*/}
          {/*}*/}


          {/*{transactionType === '전세' &&*/}
          {/*  <TableRow>*/}
          {/*  <Th>*/}
          {/*      <ThLabel>*/}
          {/*        거래가*/}
          {/*        <RequireIcon>*</RequireIcon>*/}
          {/*      </ThLabel>*/}
          {/*    </Th>*/}
          {/*    <Td colSpan={3}>*/}
          {/*      <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>*/}
          {/*        <div style={{display: 'flex', alignItems: 'center'}}>*/}
          {/*          <div style={{width: 60, fontSize: 14}}>보증금</div>*/}
          {/*          <InputText type="text" suffix={'원'} value={price} onChange={e => setPrice(e.target.value as string)}/>*/}
          {/*        </div>*/}

          {/*      </div>*/}
          {/*    </Td>*/}
          {/*  </TableRow>*/}
          {/*}*/}


          {/*{transactionType === '월세' &&*/}
          {/*  <TableRow>*/}
          {/*    <Th>*/}
          {/*      <ThLabel>*/}
          {/*        거래가*/}
          {/*        <RequireIcon>*</RequireIcon>*/}
          {/*      </ThLabel>*/}
          {/*    </Th>*/}
          {/*    <Td colSpan={3}>*/}
          {/*      <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>*/}
          {/*        <div style={{display: 'flex', alignItems: 'center'}}>*/}
          {/*          <div style={{width: 60, fontSize: 14}}>보증금</div>*/}
          {/*          <InputText type="text" suffix={'원'} value={price} onChange={e => setPrice(e.target.value as string)}/>*/}
          {/*        </div>*/}

          {/*        <div style={{display: 'flex', alignItems: 'center'}}>*/}
          {/*          <div style={{width: 60, fontSize: 14}}>월세</div>*/}
          {/*          <InputText type="text" suffix={'원'} value={price} onChange={e => setPrice(e.target.value as string)}/>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </Td>*/}
          {/*  </TableRow>*/}
          {/*}*/}


          <TableRow>
            <Th>
              <ThLabel>
                면적
              </ThLabel>
            </Th>
            <Td colSpan={3}><StyledTextArea  style={{height: 78}}
                                      placeholder={'(예시참고) 면적과 관련된 내용 기입\n공급/전용면적 : 84㎡/59㎡ (전용률 70%)\n공급면적 : 84㎡(34평)\n' +
                                        '계약면적 : 90㎡\n전용면적 : 59㎡(25평)'}
                                      value={size} onChange={e => setSize(e.target.value as string)}/></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                매물설명
              </ThLabel>
            </Th>
            <Td colSpan={3}>
              <TextArea  value={desc} onChange={e => setDesc(e.target.value as string)} />
            </Td>
          </TableRow>
          <TableRow>
          <Th>
              <ThLabel>
                첨부파일
              </ThLabel>
            </Th>
            <Td colSpan={3}>
              <FileInput type="file" key={fileInputKey} onChange={({target: {files}}) => {
                if(fileList?.length > 5){
                  message.error('사진은 최대 6개까지만 첨부할 수 있습니다.')
                  setFileInputKey(Date.now());
                } else {
                  files?.length && UploadData(files[0], 0)
                }
              }}/>
              {/*{fileList?.length > 0 &&*/}
              {/*  fileList?.map((item, index) => (*/}
              {/*    <div style={{position: 'relative', width: 120}}>*/}
              {/*      <ImageDeleteIcon style={{position: 'absolute', right: 0, cursor: 'pointer'}} onClick={() => {*/}
              {/*        setDeleteFileList([...deleteFileList, item?.urlId]);*/}
              {/*        setFileList(prev => {*/}
              {/*          const prevIndex =  prev.findIndex(i => i.urlId === item?.urlId);*/}
              {/*          prev.splice(prevIndex, 1);*/}

              {/*          return prev*/}
              {/*        });*/}
              {/*      }}/>*/}
              {/*      <img src={"https://api.myhometown-ad.co.kr/" + item?.url} key={`${item?.name}_${index}`} style={{width: 120}}/>*/}
              {/*    </div>*/}
              {/*  ))*/}
              {/*}*/}

              {localFileList?.map((item: any, index: number) => (
                <DataText key={item?.url}>
                  {item?.name}
                  <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                    setDeleteFileList([...deleteFileList, item?.urlId]);
                    setLocalFileList(prev => {
                      const newFileList = [...prev];
                      const prevIndex = newFileList.findIndex(i => i.urlId === item?.urlId);
                      if (prevIndex !== -1) {
                        newFileList.splice(prevIndex, 1);
                      }
                      return newFileList;
                    });
                  }}/>
                </DataText>
              ))}

              {fileList?.map((item: any, index: number) => (
                <DataText key={item?.url}>
                  {item?.name}
                  <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                    setFileList(prev => {
                      const newFileList = [...prev];
                      const prevIndex = newFileList.findIndex(i => i.url === item?.url);
                      if (prevIndex !== -1) {
                        newFileList.splice(prevIndex, 1);
                      }
                      return newFileList;
                    });
                  }}/>
                </DataText>
              ))}
              <ContentsSubTitle>이미지 파일 : 최대 6개</ContentsSubTitle>
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                비공개메모
              </ThLabel>
            </Th>
            <Td colSpan={3}><InputText  type="text" value={memo} onChange={e => setMemo(e.target.value as string)}/></Td>
          </TableRow>
          </tbody>
        </Table>

        <ContentsTitle>주인 정보<ContentsSubTitle>광고에 노출되지 않습니다.</ContentsSubTitle></ContentsTitle>

        <CommercialInfoWrapper>
          <InfoHeader>
            <StyledTextArea  value={hostName} placeholder={'이름, 연락처, 기타 정보 등'} onChange={e => setHostName(e.target.value as string)}/>
          </InfoHeader>
          {/*<InfoHeader>*/}
          {/*  주소 :*/}
          {/*  <InfoInput value={hostTel} onChange={e => setHostTel(e.target.value as string)} />*/}
          {/*</InfoHeader>*/}
        </CommercialInfoWrapper>

        {itemId ?
          <ButtonWrapper style={{justifyContent: isMobile ? 'center' : undefined}}>
            <ButtonLeftSide>
              <EdtiButton onClick={() => {
                setIsEditModal(true)
              }}>
                저장
              </EdtiButton>

              {!isMobile &&
                <CancelButton onClick={() => {
                  setIsPauseModal(true)
                }}>
                  광고중단
                </CancelButton>
              }

              {!isMobile &&
                <CancelButton onClick={() => {
                  putRestartCommercial()
                }}>
                  광고재개
                </CancelButton>
              }

            </ButtonLeftSide>

            {!isMobile &&
              <SignUpButton onClick={() => {
                setIsSuccessModal(true)
              }}>거래완료</SignUpButton>
            }
          </ButtonWrapper>
        :
          <ButtonWrapper>
            <ButtonLeftSide>
              <SignUpButton onClick={() => RegistRealEstate(0)}>
                등록
              </SignUpButton>

              <TempSaveButton onClick={() => RegistRealEstate(1)}>
                임시저장
              </TempSaveButton>

              <PreviewButton onClick={() => setIsPreviewModal(true)}>미리보기</PreviewButton>
            </ButtonLeftSide>

            <CancelButton onClick={() => setIsDetailModal(false)}>취소</CancelButton>
          </ButtonWrapper>
        }

      </ModalWrapper>

      {isEditModal &&  <CommercialEdit setIsEditModal={setIsEditModal} EditRealEstate={EditRealEstate} />}
      {isPauseModal &&  <CommercialPause setIsPauseModal={setIsPauseModal} itemId={itemId} mutate={mutate} setItemId={setItemId} />}
      {isSuccessModal &&  <TransactionSuccess setIsSuccessModal={setIsSuccessModal} itemId={itemId} mutate={mutate} setItemId={setItemId}/>}
      {isPreviewModal &&  <PreviewMonitor setIsPreviewModal={setIsPreviewModal} itemId={null} agentSaleItem={{fileList, title, address, type, transactionType, price, size, desc }}  />}
    </>
  )
}


const DataText = styled.div`
    font-weight: 400;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    line-height: 16.71px;
    color: #000000;
    margin-top: 8px;
    white-space: pre-wrap;
`


const DeleteItemIconSVG = styled.svg`
    width: 12px;
    cursor: pointer;
    margin-left: 10px;
`


const GreyButton = styled.div`
    height: 31px;
    width: 120px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000001A;
    cursor: pointer;
    color: #000000CC;
    margin: 0 4px;

    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
`

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 820px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 580px;
    min-height: 411px;
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @media ${media.mobile} {
        width: 100%;
        height: 100%;
    }
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Table = styled.table`
    width: 100%;
    margin-top: 8px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 30%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
    vertical-align: middle;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    display: flex;
    gap: 2px;
`

const RequireIcon = styled.p`
    color: #F15151;
`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
`;

const TdRowContents = styled.div`
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    font-family: Pretendard, sans-serif;
    color: #000000;
`

const DescText = styled.div`
  font-weight: 400;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    line-height: 16.71px;
    color: #00000099;
    margin-top: 8px;
    margin-bottom: 20px;
    white-space: pre-wrap;
`

const InputText = styled(Input)`
    width: 100%;
    height: 28px;
    padding-left: 10px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;

    &::placeholder {
        color: #535353;
    }
`;

const StyledTextArea = styled(TextArea)`
    &::placeholder {
        color: #535353;
    }
`

const FileInput = styled(Input)`
    width: 100%;
    padding: 2px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;


const StyledSelect = styled(Select)`
    width: 160px;
    border: 1px solid #00000033;
    border-radius: 7px;
`

const SignUpButton = styled.div`
    width: 100px;
    padding: 11px;
    background-color: #530DAD;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const EdtiButton = styled.div`
    width: 100px;
    padding: 11px;
    background-color: #FF6C4B;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const ContentsTitle = styled.div`
    width: 100%;
    margin-top: 20px;
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    color: #000000CC;
    display: flex;
    gap: 8px;
`

const ContentsSubTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.75px;
    color: #00000080;
`


const CommercialInfoWrapper = styled.div`
    width: 100%;
    height: 102px;
    background-color: #F5F5F5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    //padding-top: 8px;
    //padding-left: 10px;
`

const InfoHeader = styled.div`
    display: flex;
    gap: 6px;
    font-family: Pretendard, sans-serif;
    font-size: 14px;
    line-height: 16.75px;
    color: #00000099;
    align-items: center;
`

const InfoInput = styled(Input)`
    width: 459px;
    height: 25px;
    
    &::placeholder {
        color: #535353;
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

const ButtonLeftSide = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 6px;
`

const TempSaveButton = styled.div`
    width: 100px;
    padding: 11px;
    color: #FF6C4B;
    border: 1px solid #FF6C4B;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`

const PreviewButton = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
    text-decoration: underline;
    cursor: pointer;
`

const CancelButton = styled.div`
    width: 100px;
    padding: 11px;
    border: 1px solid #000000;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`