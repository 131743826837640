import React, {useEffect, useState, useTransition} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import {message, Select} from "antd";
import API from "../../../../utils/api";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import {Text} from '../../../../styles/reactnative';
import {SelectAddressItem} from "../../../../HomeTown/Customer/MediaList/blocks/SelectAddressItem";


const firebaseConfig = {
  apiKey: "AIzaSyD_rKVGvFk05UyYeq2XUc34NHiNTsDx8oE",
  authDomain: "hometown-37193.firebaseapp.com",
  projectId: "hometown-37193",
  storageBucket: "hometown-37193.appspot.com",
  messagingSenderId: "646365627133",
  appId: "1:646365627133:web:b574f16d1346f4d962f494",
  measurementId: "G-9B1MNTFY0E"
};


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();


export const HomeTownFindInfoModal = ({setIsFindInfoModal}) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [paName, setPaName] = useState('');
  const [paPhone, setPaPhone] = useState('');

  // 유틸리티
  const [isPending, startTransition] = useTransition();
  const [verificationCode, setVerificationCode] = useState('');
  const [confirmationResult, setConfirmationResult] = useState<any>(null);


  // 주소 검색
  const [selectedFirstName, setSelectedFirstName] = React.useState<any>(null);
  const [selectedSecondName, setSelectedSecondName] = React.useState<any>(null);
  const [selectedThirdName, setSelectedThirdName] = React.useState<any>(null);


  // useEffect(() => {
  //   const fullAddress = `${selectedFirstName || ''} ${selectedSecondName || ''} ${selectedThirdName || ''}`;
  //   // console.log('fullAddress', fullAddress)
  //
  //   if (selectedFirstName || selectedSecondName || selectedThirdName) {
  //     setRegion(fullAddress);
  //   }
  // }, [selectedFirstName, selectedSecondName, selectedThirdName]);


  const clickFindMyInfo = (type) => {
    const shouldEdit = window.confirm(`아이디 / 비밀번호를 찾으시겠습니까?`);
    if (shouldEdit) {
      // if(!name && !phone && !address && !email && !password && !detailAddress && !store && !region) {
      //     message.error('필수 사항을 입력해주세요.')
      // } else {

      //}

      API.post('/auth/find', '', {
        type: type,
        name : type === 'pw'? paName : name,
        phone : type === 'pw'? paPhone : phone,
        email,
      })
        .then((res: any) => {
          let windowConfirm;

          if (res.result.success) {
              console.log('res.result', res.result.result?.email)
            if(type === 'id') {
               windowConfirm = window.confirm(`아이디는 "${res.result.result?.email}" 입니다.`);
            } else {
              windowConfirm = window.confirm(`비밀번호는 "${res.result.result?.password}" 입니다.`);
            }

            if(windowConfirm) {
              setIsFindInfoModal(false);
            }
          } else {
            message.error(res.result.message || `아이디 / 비밀번호 찾기에 실패하였습니다.`);
          }
        })
        .catch((err: any) => {
          message.error(`아이디 / 비밀번호 찾기에 실패하였습니다.`);
        })
    }
  }



  return (
    <>
      <Blacked onClick={() => setIsFindInfoModal(false)}/>
      <ModalWrapper className={"scroll-container"}>
        <ModalHeader>
          <ModalTitle>아이디 찾기</ModalTitle>
          <CloseIconSVG as={ModalCloseIcon} onClick={() => {
            setIsFindInfoModal(false)
          }}/>
        </ModalHeader>

        <Table>
          <tbody>
          <TableRow>
            <Th>
              <ThLabel>
                이름
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td><Input type="text" value={name} onChange={e => setName(e.target.value)}/></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                연락처
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              <Input type="text" value={phone} onChange={e => setPhone(e.target.value)}
                     placeholder={'ex. 010-1234-1234'}/>
            </Td>
          </TableRow>
          </tbody>
        </Table>

        <SignUpButton onClick={() => clickFindMyInfo('id')}>
          아이디 찾기
        </SignUpButton>


        <Line/>

        <ModalHeader>
          <ModalTitle>비밀번호 찾기</ModalTitle>
        </ModalHeader>

        <Table>
          <tbody>
          <TableRow>
            <Th>
              <ThLabel>
                메일(아이디)
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td><Input type="text" placeholder={'test@gmail.com'} value={email}
                       onChange={e => setEmail(e.target.value)}/></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                이름
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td><Input type="text" value={paName} onChange={e => setPaName(e.target.value)}/></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                연락처
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              <Input type="text" value={paPhone} onChange={e => setPaPhone(e.target.value)}
                     placeholder={'ex. 010-1234-1234'}/>
            </Td>
          </TableRow>
          </tbody>
        </Table>

        <SignUpButton onClick={() => clickFindMyInfo('pw')}>
          비밀번호 찾기
        </SignUpButton>
      </ModalWrapper>
    </>
  )
}


const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #d2d5d5;
    margin-top: 50px;
    margin-bottom: 30px;
`

const Desc = styled.div`
    white-space: pre-wrap;
    margin-top: 10px;
    margin-left: 5px;
    font-size: 13px;
    color: #757575;
`

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 800px;
    width: 800px;
    min-height: 411px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

`;


const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Table = styled.table`
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 22%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    display: flex;
    gap: 2px;
`

const RequireIcon = styled.p`
    color: #F15151;
`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    width: 78%;
    border: 1px solid #ccc;
`;

const TdRowContents = styled.div`
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    font-family: Pretendard, sans-serif;
    color: #000000;
`

const DescText = styled.div`
    font-weight: 400;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    line-height: 16.71px;
    color: #00000099;
    margin-top: 8px;
    margin-bottom: 20px;
    white-space: pre-wrap;
`

const Input = styled.input`
    width: 100%;
    height: 28px;
    padding-left: 10px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;

const FileInput = styled.input`
    width: 100%;
    padding: 2px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;

const GreyButton = styled.div`
    height: 31px;
    width: 120px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000001A;
    cursor: pointer;
    color: #000000CC !important;
    margin: 0 4px;

    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
`

const StyledSelect = styled(Select)`
    width: 160px;
    border: 1px solid #00000033;
    border-radius: 7px;
`

const SignUpButton = styled.div`
    width: auto;
    padding: 11px;
    background-color: #530DAD;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;