import React, {useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import styled from "styled-components";
import {ReactComponent as DownloadIcon} from "../assets/DownloadIcon.svg";
import {Input, message} from "antd";
import API from "../../../../utils/api";
import {MEDIA_URL} from "../../../../app/constants/APIKEYS";
import TextArea from "antd/es/input/TextArea";
import {useToken} from "../../../../app/hooks";
import {useAgentInquireInfo} from "../../../../HomeTown/hooks/Agent/InquireList/GetAgentInquireInfo";
import {useAgentInquireAnswerInfo} from "../../../../HomeTown/hooks/Agent/InquireList/GetAgentInquireAnswerInfo";
import {useHomeInquireInfo} from "../../../../HomeTown/hooks/Admin/AdminInquire/GetHomeInquireInfo";
import {useUserInfo} from '../../../../HomeTown/hooks/Auth/GetUserInfo';

export const PasswordModal = ({isPasswordOpen, setIsPasswordOpen, mutateAdminInquiryList, passwordId, setItemId, postPassword}) => {
  // 상태값
  const [password, setPassword] = React.useState<any>('');

  const {homeInquireInfo} = useHomeInquireInfo(passwordId);

  const {data: token} = useToken();
  const {userInfo} = useUserInfo();


  const handleCheck = () => {
    console.log(password, postPassword)
    if(password === postPassword) {
      setItemId(passwordId);
      setIsPasswordOpen(false)
    } else {
      message.error('비밀번호가 틀립니다.')
    }
  }


  return (
    <>
      <Blacked onClick={() => setIsPasswordOpen(false)}/>
      <ModalWrapper className={"scroll-container"} style={{width: '500px'}}>
        <ModalHeader>
          <ModalTitle>게시판 비밀번호</ModalTitle>
          <CloseIconSVG as={ModalCloseIcon} onClick={() => {
            setIsPasswordOpen(false)
          }}/>
        </ModalHeader>

        <Table>
          <tbody>
          <TableRow>
            <ThGrey>
              <ThLabel>
                비밀번호
              </ThLabel>
            </ThGrey>
            <Td colSpan={3}>
              <Input value={password} onChange={(e) => setPassword(e.target.value)}/>
            </Td>
          </TableRow>

          </tbody>
        </Table>


        <ButtonsWrapper>

          <CancelButton
            onClick={() => {
              handleCheck();
            }}
          >
            확인
          </CancelButton>
        </ButtonsWrapper>


      </ModalWrapper>
    </>
  )
}




const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 808px;
    width: 780px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Table = styled.table`
    width: 100%;
    margin-top: 8px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 20%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
    vertical-align: middle;
`;

const ThGrey = styled.th`
    padding: 10px;
    text-align: left;
    width: 20%;
    border: 1px solid #ccc;
    background-color: #F5F5F5;
    vertical-align: middle;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;

`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
`;


const ButtonsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`


const CancelButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    border: 1px solid #0000004D;
    color: #125DE6;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const DownloadSVG = styled.svg`
    cursor: pointer;
    width: 16px;
`

const PrevNextText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    font-family: Pretendard, sans-serif;
    color: #00000099;
    cursor: pointer;
`

const ArrowSVG = styled.svg`
    width: 20px;
`

const ContentsTitle = styled.div`
    width: 100%;
    margin-top: 20px;
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    color: #000000CC;
    display: flex;
    gap: 8px;
`